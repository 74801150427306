import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import clsx from 'clsx'
import { FC, KeyboardEvent, useMemo, useRef } from 'react'

import { Button, DropdownMenu } from '@/components/atoms'
import { useTableContext } from '@/components/contexts'

import { DropdownMenuItem } from '@/types/interfaces/ui'
import { IGroupByItem } from '@/types/interfaces/table'

import styles from './GroupByDropdown.module.scss'

const CLEAR_ID = 'clear'

const GroupByDropdown: FC = () => {
  const {
    groupBy: value,
    onGroupByChange: onChange,
    groupByOptions = []
  } = useTableContext()

  const containerRef = useRef<HTMLDivElement | null>(null)

  const details: {
    options: DropdownMenuItem[]
    selectedItem: IGroupByItem | undefined
  } = useMemo(() => {
    const options: DropdownMenuItem[] = []

    const selectedItem = groupByOptions?.find?.((item) => item.id === value)

    if (value) {
      options.push({
        id: CLEAR_ID,
        label: 'Clear Group By',
        className: 'color-green500'
      })
    }

    options.push(
      ...groupByOptions.map((item) => ({
        id: item.id,
        label: item.title,
        selected: !!selectedItem
      }))
    )

    return { options, selectedItem }
  }, [value, groupByOptions])

  const { options, selectedItem } = details

  const handleOptionClick = (id: string) => {
    setTimeout(() => {
      onChange(id === CLEAR_ID ? undefined : id)
    }, 200)
  }

  return (
    <div ref={containerRef}>
      <DropdownMenu
        placement="left"
        items={options}
        onClick={handleOptionClick}
      >
        {({ toggle, open }) => (
          <Button
            small
            onClick={toggle}
            type="outlined"
            keepFocus={open}
            endIcon={
              <ArrowDropDownIcon className={clsx(open && styles.mirror)} />
            }
          >
            Group by {!!selectedItem && selectedItem.title}
          </Button>
        )}
      </DropdownMenu>
    </div>
  )
}

export default GroupByDropdown
