import * as yup from 'yup'

const ProfileSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(50, 'Max length is 50.')
    .required('First Name is required.'),
  lastName: yup
    .string()
    .trim()
    .max(50, 'Max length is 50.')
    .required('Last Name is required.')
})

export default ProfileSchema
