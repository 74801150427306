import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'
import { GateTransaction } from '@/__generated__/graphql'

interface CheckOutFormContextValue {
  setAvailablePowerUnits: (transactions: GateTransaction[]) => void
  setAvailableCargoAssets: (transactions: GateTransaction[]) => void
}

const CheckOutFormContext = createContext<CheckOutFormContextValue>(
  {} as CheckOutFormContextValue
)

const CheckOutFormContextProvider: FC<
  PropsWithChildren & CheckOutFormContextValue
> = (props) => {
  const { children, setAvailableCargoAssets, setAvailablePowerUnits } = props
  const providerValue = useMemo(
    () => ({
      setAvailableCargoAssets,
      setAvailablePowerUnits
    }),
    [setAvailableCargoAssets, setAvailablePowerUnits]
  )

  return (
    <CheckOutFormContext.Provider value={providerValue}>
      {children}
    </CheckOutFormContext.Provider>
  )
}

const useCheckOutFormContext = () => useContext(CheckOutFormContext)

export { CheckOutFormContextProvider, useCheckOutFormContext }
