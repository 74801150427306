import { TableContainer } from '@mui/material'
import clsx from 'clsx'
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { Footer } from '@/components/atoms'
import { TableContextProvider } from '@/components/contexts'
import {
  DEBOUNCE_TIME,
  MIN_SEARCH_QUERY_LENGTH,
  PAGINATION_ITEMS_PER_PAGE
} from '@/constants'
import { TableActions, TableFilterType } from '@/types/enums/table'
import {
  IDateRangeFilterValue,
  IFilterItem,
  IFilterValues,
  IGroupByItem,
  IHighOrderColumn,
  ITableColumn,
  TableRequestDetails,
  TableRequestDetailsFilters,
  TableSortValue
} from '@/types/interfaces/table'
import { IPagination } from '@/types/interfaces/ui'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'

import NoSearchResults from '@/assets/icons/no_search_results.svg?react'

import { DateRangeFilter } from '@/__generated__/graphql'
import FullSpaceTemplate from '@/components/templates/FullSpaceTemplate/FullSpaceTemplate'
import useDebouncedWindowResize from '@/hooks/useDebouncedWindowResize'
import { Color } from '@/styles/palette'
import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  ColumnVisibleEvent,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  ValueGetterParams
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Dayjs } from 'dayjs'
import { get } from 'lodash'
import { useDebounceValue, useLocalStorage } from 'usehooks-ts'
import ColumnLoading from '../../atoms/Loader/ColumnLoading'
import BaseEmptyState, {
  DEFAULT_EMPTY_STATE,
  IBaseEmptyState
} from '../EmptyState/BaseEmptyState'
import { AgGridTheme } from './AgGrid/AgGridTheme'
import TableGroupHeader from './AgGrid/components/Headers/TableGroupHeader'
import { TableHeader } from './AgGrid/components/Headers/TableHeader'
import autosizeVisibleAgGridColumns from './AgGrid/helpers/autosizeVisibleAgGridColumns'
import getAgGridWidth from './AgGrid/helpers/getAgGridWidth'
import onRowClicked from './AgGrid/helpers/onRowClicked'
import { TableFilters, TablePagination } from './components'
import TableCellRenderer from './components/TableCell/TableCellRenderer'
import styles from './Table.module.scss'

interface Totals {
  pages?: number
  items?: number
}

interface IProps {
  name: string
  loading?: boolean
  withSearch?: boolean
  columns: (groupBy: string | undefined) => ITableColumn[]
  highOrderColumns?: (groupBy: string | undefined) => IHighOrderColumn[]
  rows: any[]
  clickable?: boolean
  defaultFilters?: IFilterValues
  filters?: IFilterItem[]
  perPage?: number
  currentPage?: number
  totals?: Totals
  onUpdateRequestDetails?: Dispatch<SetStateAction<TableRequestDetails>>
  enablePagination?: boolean
  searchPlaceholder?: string
  groupByOptions?: IGroupByItem[]
  EmptyScreen?: ReactNode
  isRowHighlighted?: (row: any) => boolean
  handleAction?: (action: TableActions, row: any) => void
  noResultsMessage?: IBaseEmptyState
  rowHeight?: number
  headerHeight?: number
}

interface PreparedFilters {
  filters: TableRequestDetailsFilters | undefined
  dateRange: DateRangeFilter | undefined
}

const DEFAULT_ROW_HEIGHT = 40
const DEFAULT_HEADER_HEIGHT = 25

const CustomTable: FC<IProps> = (props) => {
  const {
    name,
    columns,
    rows = [],
    withSearch = false,
    loading = false,
    currentPage,
    totals,
    onUpdateRequestDetails,
    perPage = PAGINATION_ITEMS_PER_PAGE,
    clickable,
    filters,
    groupByOptions,
    defaultFilters,
    handleAction,
    isRowHighlighted,
    highOrderColumns,
    searchPlaceholder = 'Search',
    enablePagination = true,
    noResultsMessage = DEFAULT_EMPTY_STATE,
    EmptyScreen,
    rowHeight = DEFAULT_ROW_HEIGHT,
    headerHeight = DEFAULT_HEADER_HEIGHT
  } = props
  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [groupBy, setGroupBy] = useState<string | undefined>()
  const [filterValues, setFilterValues] = useState<IFilterValues>({})
  const [page, setPage] = useState<number>(currentPage || 1)
  const [sortBy, setSortBy] = useState<TableSortValue | undefined>()
  const [gridApi, setGridApi] = useState<GridApi | undefined>()

  const [columnState, setColumnState] = useLocalStorage<ColumnState[]>(
    `${name}_grid_column_state`,
    []
  )

  const pagination: IPagination = {
    perPage,
    currentPage: page,
    totalItems: totals?.items || rows.length,
    totalPages: totals?.pages || Math.ceil(rows.length / perPage)
  }

  const [debouncedSearch] = useDebounceValue(searchValue, DEBOUNCE_TIME)

  const memoColumns: ITableColumn[] = useMemo(() => columns(groupBy), [groupBy])
  const memoHighOrderCols: IHighOrderColumn[] | undefined = useMemo(
    () => highOrderColumns?.(groupBy),
    [groupBy]
  )

  const groupedColumnDefs: ColDef[] = useMemo(() => {
    // Collect higher level columns' children
    if (!memoHighOrderCols || memoHighOrderCols.length === 0) {
      return memoColumns.map((column: ITableColumn) => {
        return {
          field: column.id,
          headerName: column.title,
          cellRenderer: (params: ICellRendererParams) => {
            return TableCellRenderer({ params, column, handleAction })
          },
          sortable: !!column.sortable,
          resizable: false,
          enableRowGroup: false,
          headerComponent: TableHeader,
          headerComponentParams: {
            sortBy: sortBy,
            setSortBy: setSortBy,
            columns: memoColumns
          },
          enableCellTextSelection: true,
          ensureDomOrder: true
        }
      })
    }

    let columnIndex = 0

    // Group child columns under high order columns
    return memoHighOrderCols.map((highOrderCol) => {
      const childColumns = memoColumns.slice(
        columnIndex,
        columnIndex + highOrderCol.colSpan
      )
      columnIndex += highOrderCol.colSpan

      return {
        headerName: highOrderCol.title,
        headerGroupComponent: TableGroupHeader,
        headerGroupComponentParams: {
          displayName: highOrderCol.title
        },
        children: childColumns.map((column: ITableColumn) => ({
          field: column.id,
          headerName: column.title,
          valueGetter: (params: ValueGetterParams) => {
            const ret = get(params.data, column.id)
            if (column.converter) {
              return column.converter(
                params.data,
                column.id,
                column.friendlyNameMapper
              )
            }
            return ret
          },
          cellRenderer: (params: ICellRendererParams) => {
            return TableCellRenderer({
              params,
              column,
              handleAction
            })
          },
          sortable: true,
          resizable: false,
          lockPosition: false,
          suppressMovable: false,
          hide: !column.defaultVisible,
          headerComponent: TableHeader,
          headerComponentParams: {
            sortBy: sortBy,
            setSortBy: setSortBy,
            columns: memoColumns
          },
          enableCellTextSelection: true,
          ensureDomOrder: true
        }))
      }
    })
  }, [memoHighOrderCols, memoColumns, sortBy])

  const memoRows: any[] = useMemo(() => {
    // For client side pagination
    if (rows.length && enablePagination && !onUpdateRequestDetails) {
      const start = (pagination.currentPage - 1) * pagination.perPage
      const end = Math.min(
        pagination.currentPage * pagination.perPage,
        pagination.totalItems
      )

      return rows.slice(start, end)
    }

    return rows
  }, [rows, enablePagination, pagination])

  const { showFilters, showSearch, hasRows, isEmpty, isFilterApplied } =
    useMemo(() => {
      const isFilterApplied =
        Object.values(filterValues || {}).some(
          (filterValue) => !!filterValue
        ) || !!searchValue

      return {
        isFilterApplied,
        showSearch: withSearch,
        showFilters:
          withSearch || Array.isArray(groupByOptions) || Array.isArray(filters),

        hasRows: !!memoRows.length && !loading,
        isEmpty: !memoRows.length && !isFilterApplied && !loading
      }
    }, [filterValues, searchValue, memoRows, loading, withSearch])

  const onFilterChange = (filters: IFilterValues) => {
    setFilterValues(filters)

    const preparedFilters: PreparedFilters = Object.entries(filters).reduce(
      (acc: PreparedFilters, [key, value]) => {
        const filter = props.filters?.find((filter) => filter.id === key)

        if (!value) return acc

        switch (filter?.type) {
          case TableFilterType.List: {
            acc.filters = {
              ...(acc.filters || {}),
              [key]: filter.requestFormatter(value as string[])
            }

            break
          }

          case TableFilterType.DateRange: {
            acc.dateRange = filter.requestFormatter(
              value as IDateRangeFilterValue
            )
            break
          }
          case TableFilterType.Dwell: {
            acc.dateRange = filter.requestFormatter(
              filterValues?.dateRange,
              value as Dayjs
            )
            break
          }

          default: {
            break
          }
        }

        return acc
      },
      { filters: undefined, dateRange: undefined } as PreparedFilters
    )

    onUpdateRequestDetails?.((prev) => ({
      ...prev,
      filters: preparedFilters.filters,
      dateRange: preparedFilters.dateRange
    }))
  }

  const onSearchChange = useCallback(
    (newValue: string | undefined) => {
      setSearchValue(newValue)

      if (!newValue || newValue?.length >= MIN_SEARCH_QUERY_LENGTH) {
        onUpdateRequestDetails?.((prev) => ({
          ...prev,
          search: newValue || '',
          currentPage: 1
        }))
      }
    },
    [onUpdateRequestDetails]
  )

  const providerValue = useMemo(
    () => ({
      filterValues,
      groupBy,
      searchPlaceholder,

      filters,
      groupByOptions,

      isRowHighlighted,
      handleAction,
      onSearchChange,
      setFilterValues: onFilterChange,
      onGroupByChange: setGroupBy
    }),
    [
      filterValues,
      groupBy,
      filters,
      groupByOptions,
      searchPlaceholder,
      onSearchChange,
      handleAction,
      isRowHighlighted,
      onFilterChange
    ]
  )

  const clearFilters = () => {
    onSearchChange('')
    onFilterChange({})
  }

  useEffect(() => {
    onUpdateRequestDetails?.((prev) => ({
      ...prev,
      sortBy
    }))
  }, [sortBy])

  const handlePageChange = (value: number) => {
    onUpdateRequestDetails?.((prev) => ({
      ...prev,
      currentPage: value
    }))

    setPage(value)
  }

  useEffect(() => {
    if (defaultFilters) {
      setFilterValues(defaultFilters)
    }
  }, [defaultFilters])

  useEffect(() => {
    if (
      !debouncedSearch ||
      debouncedSearch?.length >= MIN_SEARCH_QUERY_LENGTH
    ) {
      onUpdateRequestDetails?.((prev) => ({
        ...prev,
        search: debouncedSearch || '',
        currentPage: 1
      }))
    }
  }, [debouncedSearch])

  useDebouncedWindowResize(() => {
    if (gridApi) autosizeVisibleAgGridColumns(gridApi)
  })

  const onGridReady = (params: GridReadyEvent) => {
    const api = params.api
    setGridApi(api)

    if (columnState.length !== 0) {
      api.applyColumnState({ state: columnState, applyOrder: true })
      return
    }

    autosizeVisibleAgGridColumns(api)
  }

  const resetColumnState = () => {
    const defaultVisibleIds = memoColumns
      .filter((column) => column.defaultVisible)
      .map((column) => column.id)
    const newState = columnState
      .map((column) => ({
        ...column,
        hide: !defaultVisibleIds.includes(column.colId)
      }))
      .sort((a, b) => {
        return (
          defaultVisibleIds.indexOf(a.colId) -
          defaultVisibleIds.indexOf(b.colId)
        )
      })

    gridApi?.applyColumnState({
      state: newState,
      applyOrder: true
    })
  }

  const noResultsOverlay = (
    <>{EmptyScreen || <BaseEmptyState {...noResultsMessage} />}</>
  )

  const noResults = (
    <BaseEmptyState
      Icon={<NoSearchResults />}
      primaryText={`No search results ${searchValue ? `for '${searchValue}'` : ''}`}
      descriptionText={
        'Try adjusting your search, checking your spelling, or start a new search.'
      }
      buttonAction={clearFilters}
      buttonText={searchValue ? 'Clear Search' : 'Clear Filters'}
    />
  )

  const defaultColDef = useMemo(
    () => ({
      cellStyle: {
        fontSize: '13px',
        color: Color.gray700,
        cursor: clickable ? 'pointer' : 'default'
      }
    }),
    [clickable]
  )

  const onColumnMoved = (event: ColumnMovedEvent) => {
    if (!event.finished) return
    const { api } = event
    if (!api) return
    persistColumnState(api)
    api.refreshCells()
  }

  const onColumnVisibile = (event: ColumnVisibleEvent) => {
    autosizeVisibleAgGridColumns(event.api)
  }

  // Callback to invoke when a column's size changes typically due to calling api.autoSizeColumns().
  // This function then calculates the total current width and available width,
  // and distributes the excess width evenly across all visible columns, persisting the computed state when finished.
  const onColumnResized = (event: ColumnResizedEvent) => {
    const gridApi = event.api
    const columns = gridApi.getColumnState()
    const visibleColumns = columns.filter((column) => !column.hide)

    // Store off minimum widths for each column
    const startingColumnState = gridApi.getColumnState()
    const minWidths = new Map(
      startingColumnState.map((col) => [col.colId, col.width])
    )

    // Calculate total current width and available width
    const minWidthsSum = visibleColumns.reduce(
      (sum, column) => sum + (minWidths.get(column.colId) ?? 0),
      0
    )
    const availableWidth = getAgGridWidth()
    const excessWidth = availableWidth - minWidthsSum
    const excessWidthPerColumn = excessWidth / visibleColumns.length

    // This guards against the below call to gridApi.applyColumnState
    if (Math.abs(excessWidthPerColumn) < 1 || excessWidthPerColumn < 0) {
      persistColumnState(gridApi)
      return
    }

    // Apply the new width to each column
    gridApi.applyColumnState({
      state: visibleColumns.map((column) => {
        const minWidth = minWidths.get(column.colId) ?? 0
        return {
          colId: column.colId,
          width: excessWidthPerColumn + minWidth
        }
      }),
      applyOrder: true
    })
  }

  const persistColumnState = (api: GridApi) => {
    const newColumnState = api.getColumnState()
    if (!newColumnState) return
    setColumnState(newColumnState)
  }

  const materialAgGrid = (
    <div
      className={clsx(AgGridTheme.Material, styles.materialTableThemeWrapper)}
    >
      {loading ? (
        <ColumnLoading />
      ) : (
        <AgGridReact
          onColumnMoved={onColumnMoved}
          onRowClicked={(params) =>
            onRowClicked(params, clickable, handleAction)
          }
          onColumnVisible={onColumnVisibile}
          onColumnResized={onColumnResized}
          onGridReady={onGridReady}
          aria-labelledby={name}
          className={clsx('border-top', clickable && 'clickable', 'tw-w-full')}
          rowData={memoRows}
          columnDefs={groupedColumnDefs}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          defaultColDef={defaultColDef}
          enableCellTextSelection
          components={{
            noRowsOverlay: noResultsOverlay,
            noResultsOverlay: noResultsOverlay
          }}
          pagination={false}
        />
      )}
    </div>
  )

  return (
    <TableContextProvider value={providerValue}>
      <FullSpaceTemplate columnMode className="background-color-gray0">
        {showFilters && (
          <TableFilters
            columns={memoColumns}
            savedSearchValue={searchValue}
            showSearch={showSearch}
            columnState={columnState}
            setColumnState={setColumnState}
            gridApi={gridApi!}
            resetColumnState={resetColumnState}
            higherOrderColumns={memoHighOrderCols}
          />
        )}

        {!hasRows && isFilterApplied && !loading ? (
          noResults
        ) : (
          <div
            className={clsx(styles.tableWrapper, hasRows && styles.withItems)}
          >
            <TableContainer className={styles.tableContainer}>
              {isEmpty ? (
                <>{EmptyScreen || <BaseEmptyState {...noResultsMessage} />}</>
              ) : (
                materialAgGrid
              )}
              <Footer>
                <TablePagination
                  pagination={pagination}
                  onPageChange={handlePageChange}
                />
              </Footer>
            </TableContainer>
          </div>
        )}
      </FullSpaceTemplate>
    </TableContextProvider>
  )
}

export default CustomTable
