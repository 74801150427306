import { TableSortDirection } from '@/types/enums/table'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const DEFAULT_SIZE = 18

interface Props {
  direction?: TableSortDirection
  size?: number
}

const SortDirectionArrow = ({ direction, size = DEFAULT_SIZE }: Props) => {
  if (!direction) return <></>

  const Wrapper =
    direction === TableSortDirection.Asc ? ArrowUpwardIcon : ArrowDownwardIcon

  return (
    <Wrapper
      style={{
        width: size,
        height: size
      }}
    />
  )
}

export default SortDirectionArrow
