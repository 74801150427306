import { useCallback, useEffect, useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useWindowSize } from 'usehooks-ts'

import { Tabs } from '@/components/molecules'
import { FullSpaceTemplate, WithHeaderTemplate } from '@/components/templates'
import { BREAKPOINTS } from '@/constants'
import { SitePortalPermissions } from '@/features/auth/types'
import { useLastUrlPart } from '@/hooks'
import { useNavigator } from '@/router'
import { useStore } from '@/store'
import { ITab } from '@/types/interfaces/ui'

import { GateTab } from '../types'
import { GateQueueTemplate } from './GateQueueTemplate'
import { GateTransactionTemplate } from './GateTransactionTemplate'

export const GateTemplate = () => {
  const { selectedPortal } = useStore((store) => store.user)
  const permissions = selectedPortal?.permissions as
    | SitePortalPermissions
    | undefined

  const hasGateQueueAccess = !!permissions?.gate_queue
  const hasGateTransactionAccess = !!permissions?.gate_transaction

  const { site_id, gate_id } = useParams()
  const navigator = useNavigator(site_id, gate_id)

  const { width } = useWindowSize()
  const isSmallView = width <= BREAKPOINTS.SM

  const activeTab = useLastUrlPart() as GateTab

  const setTab = useCallback(
    (tab: GateTab | undefined) => {
      if (tab === GateTab.Queue) navigator.toGateQueue()
      else navigator.toGateTransactions()
    },
    [navigator]
  )

  // On initial page view, check permissions for validity of route
  useEffect(() => {
    // Automatic redirect will happen to first available page from MainTemplate if user has no permissions
    if (!hasGateQueueAccess && !hasGateTransactionAccess) return

    const existingTabSelected = Object.values(GateTab).includes(activeTab)

    if (existingTabSelected) {
      // On queue but route not authorized
      if (!hasGateQueueAccess) setTab(GateTab.Transactions)
      return
    }

    setTab(hasGateQueueAccess ? GateTab.Queue : GateTab.Transactions)
  }, [activeTab, permissions])

  const authorizedTabs: ITab<GateTab>[] = useMemo(() => {
    if (!permissions) return []

    const tabs: ITab<GateTab>[] = []

    if (hasGateQueueAccess) {
      tabs.push({
        id: GateTab.Queue,
        title: isSmallView ? 'Gate Queue' : 'Queue',
        Component: <GateQueueTemplate />
      })
    }

    if (hasGateTransactionAccess) {
      tabs.push({
        id: GateTab.Transactions,
        title: isSmallView ? 'Gate Transactions' : 'Transactions',
        Component: <GateTransactionTemplate />
      })
    }

    return tabs
  }, [permissions, isSmallView])

  return (
    <WithHeaderTemplate
      title={hasGateQueueAccess ? 'Gate' : 'Gate Transactions'}
      dropdownTabs={isSmallView ? authorizedTabs : []}
      showBottomBorder={isSmallView}
    >
      {isSmallView ? (
        <Outlet />
      ) : (
        <>
          {hasGateQueueAccess ? (
            <FullSpaceTemplate>
              <Tabs
                active={activeTab}
                setActive={setTab}
                tabs={authorizedTabs}
              />
            </FullSpaceTemplate>
          ) : (
            <GateTransactionTemplate />
          )}
        </>
      )}
    </WithHeaderTemplate>
  )
}
