import { FC } from 'react'

import styles from './InviteUserWithSsoBadge.module.scss'

import { Checkbox, Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextAlign, TextTypes } from '@/types/enums/ui'
import { Alert } from '@mui/material'
import clsx from 'clsx'

interface IProps {
  value: boolean
  onChange: () => void
}

const InviteUserWithSsoBadge: FC<IProps> = (props) => {
  const { value, onChange } = props

  return (
    <Alert
      icon={<Checkbox value={value} onChange={onChange} />}
      className={clsx(
        styles.ssoAlertContainer,
        value ? styles.transparentBackground : styles.defaultBackground
      )}
    >
      <Col gap={4} className={styles.ssoBadgeContainer} items='start' justify='start'>
        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.REGULAR}
          align={TextAlign.CENTER}
          color={Color.gray800}
        >
          Okta SSO Required
        </Text>
        <Text
          type={TextTypes.TEXT_XS}
          weight={FontWeight.REGULAR}
          align={TextAlign.CENTER}
          color={Color.gray600}
        >
          User will only be able to sign in using Okta.
        </Text>
      </Col>
    </Alert>
  )
}

export default InviteUserWithSsoBadge
