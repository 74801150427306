import { useState } from 'react'

import IsrData from '@/assets/icons/isr_data.svg?react'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { UpdateIsrReportModal } from '@/features/isr'

import { GetISRReportsQuery } from '@/features/isr/api/queries'
import { isrColumns, isrHighOrderColumns } from '@/features/isr/utils'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'
import {
  DEFAULT_TABLE_REQUEST_DETAILS,
  TableRequestDetails
} from '@/types/interfaces/table'
import { useQuery } from '@apollo/client'

import {
  IsrEventPair,
  OrderByIsrRecordColumn,
  SearchDomainEventColumns,
  StringOperator
} from '@/__generated__/graphql'
import { prepareSortForGraphQlApi } from '@/api'
import { useCheckMyPermissionsQuery } from '@/features/auth/api'
import { LaneRelation, PermissionResourceType } from '@/features/auth/enums'
import { preparePermissionResourceQuery } from '@/features/auth/helpers'
import {
  dateRangeFilter,
  emissionTypeFilter,
  fuelTypeFilter,
  mismatchFilter,
  weightClassFilter
} from '@/utils/table'

const ISRTemplate = () => {
  const [selectedItem, setSelectedItem] = useState<IsrEventPair | undefined>()
  const { selectedPortal, org } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [
    { currentPage, search, sortBy, filters, dateRange },
    setRequestDetails
  ] = useState<TableRequestDetails>(DEFAULT_TABLE_REQUEST_DETAILS)

  const { loading, data, refetch } = useQuery(GetISRReportsQuery, {
    variables: {
      input: {
        organizationID: org_id,
        siteID: site_id,
        currentPage,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
        search: {
          fields: [
            SearchDomainEventColumns.PowerUnitOwnerId,
            SearchDomainEventColumns.PowerUnitLicensePlateNumber,
            SearchDomainEventColumns.PowerUnitCarrierUsdot,
            SearchDomainEventColumns.PowerUnitVin,
            SearchDomainEventColumns.AccountName,
            SearchDomainEventColumns.DriverName,
            SearchDomainEventColumns.DriverLicenseNumber,
            SearchDomainEventColumns.CargoAssetOwnerId,
            SearchDomainEventColumns.CargoAssetLicensePlateNumber,
            SearchDomainEventColumns.ShipmentNumber,
            SearchDomainEventColumns.SealNumbers
          ],
          operator: StringOperator.Contains,
          value: search
        },
        order: prepareSortForGraphQlApi<OrderByIsrRecordColumn>(sortBy),
        filter: filters,
        dateRange
      }
    }
  })

  const reports = data?.listISRRecords?.ISRRecords || []
  const laneId = reports?.[0]?.ciMetadata?.laneId
  const pagination = data?.listISRRecords?.pagination

  const { currentData: updatePermission, isFetching } =
    useCheckMyPermissionsQuery(
      {
        orgId: org_id,
        relation: LaneRelation.UpdateIsrRecord,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Lane,
          laneId
        )
      },
      { skip: !laneId }
    )

  const isUpdateAllowed = updatePermission?.data?.has_permission

  const { visible, openModal, closeModal: close } = useModal(false)

  const closeModal = () => {
    close()
    setSelectedItem(undefined)
  }

  const handleAction = (action: TableActions, row: IsrEventPair) => {
    switch (action) {
      case TableActions.RowClick: {
        if (!isUpdateAllowed) return

        openModal()
        setSelectedItem(row)
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <WithHeaderTemplate title="ISR">
      {visible && (
        <UpdateIsrReportModal
          item={selectedItem as IsrEventPair}
          closeModal={closeModal}
          refetch={refetch}
        />
      )}

      <div className="tw-flex-1 tw-flex">
        <Table
          withSearch
          name="isr-records"
          rows={reports}
          clickable={isUpdateAllowed}
          loading={loading || isFetching}
          filters={[
            dateRangeFilter,
            weightClassFilter,
            fuelTypeFilter,
            emissionTypeFilter,
            mismatchFilter
          ]}
          columns={isrColumns}
          handleAction={handleAction}
          highOrderColumns={isrHighOrderColumns}
          searchPlaceholder="Search Transaction"
          perPage={PAGINATION_ITEMS_PER_PAGE}
          currentPage={currentPage}
          totals={{
            items: pagination?.totalItemsCount,
            pages: pagination?.totalPages
          }}
          onUpdateRequestDetails={setRequestDetails}
          noResultsMessage={{
            Icon: <IsrData />,
            primaryText: 'No ISR data available',
            descriptionText:
              'Once power units are checked in, ISR data will appear here'
          }}
        />
      </div>
    </WithHeaderTemplate>
  )
}

export default ISRTemplate
