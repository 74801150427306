import { Dayjs } from 'dayjs'
import { ReactNode } from 'react'

import { ChipVariant } from '@/components/atoms/Chip/Chip'
import {
  TableColumnType,
  TableFilterType,
  TableSortDirection
} from '@/types/enums/table'
import {
  ArrayFilter,
  DateRangeFilter,
  StringFilter
} from '@/__generated__/graphql'

export interface IHighOrderColumn {
  id: string
  title: string
  colSpan: number
}

export interface ITableColumnBase {
  id: string
  title: string
  type: TableColumnType
  sortable?: boolean
  sortId?: string
  className?: string
  danger?: (row: any) => boolean
  converter?: (
    row: any,
    id: string,
    friendlyNameMapper?: Record<string, string>
  ) => void
  friendlyNameMapper?: Record<string, string>,
  defaultVisible?: boolean
}

type IActionGetLabel = (row: any) => string

export interface IAction {
  id: string
  label: string | IActionGetLabel
  className?: string
  icon?: ReactNode
}

export interface ITableActionsColumn extends ITableColumnBase {
  type: TableColumnType.Actions
  actions: IAction[]
}

export interface ITableActionColumn extends ITableColumnBase {
  type: TableColumnType.Action
  actionName: string
}

export interface ITableChipColumn extends ITableColumnBase {
  type: TableColumnType.Chip
  getLabel: (row: any) => string
  getVariant: (row: any) => ChipVariant
}

export type ITableColumn =
  | ITableColumnBase
  | ITableActionColumn
  | ITableActionsColumn
  | ITableChipColumn

export interface IGroupByItem {
  id: string
  title: string
}

interface IFilterItemBase {
  id: string
  name: string
  type: TableFilterType
}

export interface IFilterListOption {
  id: string
  label: string
}

export interface IFilterList extends IFilterItemBase {
  type: TableFilterType.List
  placeholder: string
  options: IFilterListOption[]
  requestFormatter: (value: string[]) => StringFilter | ArrayFilter
}

export interface IFilterDateRange extends IFilterItemBase {
  type: TableFilterType.DateRange
  requestFormatter: (value: IDateRangeFilterValue) => DateRangeFilter
}

export interface IFilterDwell extends IFilterItemBase {
  type: TableFilterType.Dwell
  requestFormatter: (
    dateRangeFilterValue: IDateRangeFilterValue | undefined,
    value: Dayjs
  ) => DateRangeFilter
}

export type IFilterItem = IFilterList | IFilterDateRange | IFilterDwell

export type IFilterValues = {
  dateRange?: DateRangeFilter
} & Record<string, string[] | Dayjs>

export interface IDateRangeFilterValue {
  startDate?: Dayjs
  endDate?: Dayjs
}

export interface TableSortValue {
  field: string
  direction: TableSortDirection
}

export type TableRequestDetailsFilters = Record<
  string,
  StringFilter | ArrayFilter
>

export interface TableRequestDetails {
  currentPage: number
  search: string | undefined
  sortBy: TableSortValue | undefined
  dateRange?: DateRangeFilter
  filters?: TableRequestDetailsFilters
}

export const DEFAULT_TABLE_REQUEST_DETAILS: TableRequestDetails = {
  currentPage: 1,
  search: '',
  sortBy: undefined,
  filters: undefined,
  dateRange: undefined
}
