import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  emissionTypesFriendlyNames,
  fuelTypesFriendlyNames,
  weightClassesFriendlyNames
} from '@/utils/mappers'
import {
  arrayToString,
  sealMatchesToBoolean,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'
import friendlyName from '@/utils/table/converters/friendlyName'
import { IsrEventPair, OrderByIsrRecordColumn } from '@/__generated__/graphql'

const groupTitleMapper: { [key: string]: string } = {
  emissionType: 'Emission Type'
}

const isrColumns = (groupBy: string | undefined): ITableColumn[] => [
  ...(groupBy
    ? [
        {
          id: 'title',
          title: groupTitleMapper[groupBy] || 'Group',
          type: TableColumnType.Text,
          className: 'color-green500',
          sortable: true
        }
      ]
    : []),

  // TRANSACTION
  {
    id: 'ciEventTime',
    title: 'Check-In Time',
    type: TableColumnType.Date,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CiEventTime,
    defaultVisible: true
  },
  {
    id: 'coEventTime',
    title: 'Check-Out Time',
    type: TableColumnType.Date,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CoEventTime,
    defaultVisible: true
  },

  // POWER UNIT
  {
    id: 'ciMetadata.powerUnitOwnerId',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.PowerUnitOwnerId
  },
  {
    id: 'powerUnitLicensePlateNumber',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.PowerUnitLicensePlateNumber,
    defaultVisible: true
  },
  {
    id: 'powerUnitLicensePlateState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true,
    sortId: OrderByIsrRecordColumn.PowerUnitLicensePlateState,
    defaultVisible: true
  },
  {
    id: 'ciMetadata.powerUnitCarrierUsdot',
    title: 'USDOT',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.PowerUnitCarrierUsdot
  },
  {
    id: 'ciMetadata.powerUnitVin',
    title: 'VIN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.PowerUnitVin,
    defaultVisible: true
  },
  {
    id: 'ciMetadata.powerUnitWeightClass',
    title: 'Class',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: weightClassesFriendlyNames,
    sortable: true,
    sortId: OrderByIsrRecordColumn.PowerUnitWeightClass,
    defaultVisible: true
  },
  {
    id: 'ciMetadata.powerUnitFuelType',
    title: 'Fuel Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: fuelTypesFriendlyNames,
    sortable: true,
    sortId: OrderByIsrRecordColumn.PowerUnitFuelType,
    defaultVisible: true
  },
  {
    id: 'ciMetadata.powerUnitEmissionType',
    title: 'Emission',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: emissionTypesFriendlyNames,
    sortable: true,
    sortId: OrderByIsrRecordColumn.PowerUnitEmissionType
  },
  {
    id: 'ciMetadata.accountName',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByIsrRecordColumn.AccountName
  },

  // DRIVER DETAILS
  {
    id: 'driverName',
    title: 'Name',
    type: TableColumnType.Text,
    sortable: true,
    converter: (row: IsrEventPair) =>
      `${row.coMetadata.driverFirstName} ${row.coMetadata.driverLastName}`,
    sortId: OrderByIsrRecordColumn.DriverName
  },
  {
    id: 'ciMetadata.driverLicenseNumber',
    title: 'License',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.DriverLicenseNumber
  },

  // CHECK-IN CARGO ASSET
  {
    id: 'ciMetadata.cargoAssetOwnerId',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CiCargoAssetId
  },
  {
    id: 'ciMetadata.cargoAssetLicensePlateNumber',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CiCargoAssetLicensePlateNumber
  },
  {
    id: 'ciMetadata.cargoAssetLicensePlateState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CiCargoAssetLicensePlateState
  },
  {
    id: 'ciMetadata.shipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CiCargoAssetShipmentNumber
  },
  {
    id: 'ciMetadata.sealNumbers',
    title: 'Seal #',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CiCargoAssetSealNumber
  },
  {
    id: 'ciMetadata.sealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: sealMatchesToBoolean,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CiCargoAssetSealMatches
  },

  // CHECK-OUT CARGO ASSET
  {
    id: 'coMetadata.cargoAssetOwnerId',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CoCargoAssetId
  },
  {
    id: 'coMetadata.cargoAssetLicensePlateNumber',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CoCargoAssetLicensePlateNumber
  },
  {
    id: 'coMetadata.cargoAssetLicensePlateState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CoCargoAssetLicensePlateState
  },
  {
    id: 'coMetadata.shipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CoCargoAssetShipmentNumber
  },
  {
    id: 'coMetadata.sealNumbers',
    title: 'Seal #',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CoCargoAssetShipmentNumber
  },
  {
    id: 'coMetadata.sealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: sealMatchesToBoolean,
    sortable: true,
    sortId: OrderByIsrRecordColumn.CoCargoAssetSealMatches
  },
  {
    id: 'ciEventUser',
    title: 'Source',
    type: TableColumnType.Text
  },
  {
    id: 'coMetadata.mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true,
    sortId: OrderByIsrRecordColumn.Mismatch
  }
]

export default isrColumns
