import { FC } from 'react'

import { Button, Text } from '@/components/atoms'
import { Modal } from '@/components/organisms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'

interface IProps extends IModalWithCloseFn {
  onDisable: () => void
  fullName: string
  firstName: string
  loading: boolean
}

const DisableUserModal: FC<IProps> = (props) => {
  const { closeModal, onDisable, fullName, firstName, loading } = props

  return (
    <Modal
      size="md"
      title="Deactivate User"
      closeModal={closeModal}
      cancelButtonText="Cancel"
      footer={
        <Button type="primary" onClick={onDisable} disabled={loading}>
          {loading ? 'Loading...' : 'Deactivate User'}
        </Button>
      }
    >
      <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
        Are you sure you want to deactivate <b>{fullName}</b>? <br />
        <br />
        Once deactivated, <b>{firstName || fullName}</b> will no longer be able
        to log in or access any data. You can activate the user at any time to
        restore access
      </Text>
    </Modal>
  )
}

export default DisableUserModal
