import { useEffect, useRef } from 'react'

const DEFAULT_DEBOUNCE_TIME = 100
const RESIZE = 'resize'

const useDebouncedWindowResize = (
  callback: () => void,
  delay = DEFAULT_DEBOUNCE_TIME
) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    function handleResize() {
      if (timerRef.current) clearTimeout(timerRef.current)

      timerRef.current = setTimeout(() => {
        callback()
      }, delay)
    }

    window.addEventListener(RESIZE, handleResize)

    return () => {
      window.removeEventListener(RESIZE, handleResize)
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [callback, delay])
}

export default useDebouncedWindowResize
