import { TableSortDirection } from '@/types/enums/table'
import { ITableColumn, TableSortValue } from '@/types/interfaces/table'
import { IHeader, IHeaderParams } from 'ag-grid-community'
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useImperativeHandle,
  useMemo
} from 'react'
import SortDirectionArrow from '../SortDirectionArrow'
import Row from '@/components/atoms/Row/Row'
import clsx from 'clsx'

import styles from './TableHeader.module.scss'

interface TableHeaderProps extends IHeaderParams {
  sortBy: TableSortValue | undefined
  setSortBy: Dispatch<SetStateAction<TableSortValue | undefined>>
  columns: ITableColumn[]
  setAdjustingSort: Dispatch<SetStateAction<boolean>>
  higherOrderHeader?: boolean
}

export const TableHeader = forwardRef<IHeader, TableHeaderProps>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      refresh: () => true
    }))

    const {
      sortBy,
      setSortBy,
      columns,
      setAdjustingSort,
      column,
      higherOrderHeader
    } = props

    const currentSortDirection: TableSortDirection | undefined = useMemo(() => {
      const columnId = column.getColId()
      const matchingColumn = columns.find((col) => col.id === columnId)

      if (sortBy?.field === matchingColumn?.sortId) {
        return sortBy?.direction
      }
      return undefined
    }, [sortBy, columns])

    const onHeaderClicked = () => {
      const columnId = column.getColId()
      const matchingDefinition = columns.find((col) => col.id === columnId)
      if (!matchingDefinition?.sortable) return

      const sortId = matchingDefinition.sortId
      if (!sortId) {
        setSortBy(undefined)
        return
      }

      // If already sorting on this field, cycle direction
      if (sortBy?.field === sortId) {
        if (sortBy?.direction === TableSortDirection.Asc) {
          setSortBy({
            field: sortId,
            direction: TableSortDirection.Desc
          })
        } else {
          setSortBy(undefined)
        }
      } else {
        setSortBy({
          field: sortId,
          direction: TableSortDirection.Asc
        })
      }
    }

    return (
      <Row
        className={clsx('ag-header-cell-label', styles.tableHeader)}
        onClick={onHeaderClicked}
      >
        <span className="ag-header-cell-text">{props.displayName}</span>
        <SortDirectionArrow direction={currentSortDirection} />
      </Row>
    )
  }
)
