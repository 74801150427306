import { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { Chip, Col, Input, ReadOnlyFormValue, Text } from '@/components/atoms'
import { useSsoEnabled } from '@/features/home/hooks/useSsoEnabled'
import { SsoEnabledState } from '@/features/home/types/sso'
import { IUser } from '@/features/user-management/types'
import { EditUserSchemaType } from '@/features/user-management/utils'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { FormRow } from '@/features/forms/components'

import InviteUserWithSsoBadge from '../SsoBadges/InviteUserWithSsoBadge'
import SsoRequiredBadge from '../SsoBadges/SsoRequiredBadge'

interface IProps {
  user: IUser | undefined
}

const ProfileDetailsFormSection: FC<IProps> = (props) => {
  const { user } = props
  const { disabled, id } = user || {}

  const editMode = !!id

  const { ssoEnabled } = useSsoEnabled()

  const {
    register,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext<EditUserSchemaType>()

  const useSso = watch('useSso')

  const SsoComponent = useMemo(() => {
    if (editMode) {
      const userHasSso = user && user.has_sso
      if (userHasSso) {
        return <SsoRequiredBadge />
      }

      return <></>
    } else {
      if (ssoEnabled === SsoEnabledState.Enabled) {
        return (
          <InviteUserWithSsoBadge
            value={useSso}
            onChange={() => {
              setValue('useSso', !useSso)
            }}
          />
        )
      }

      return <></>
    }
  }, [ssoEnabled, editMode, useSso])

  return (
    <Col gap={8} items="stretch">
      {!editMode && (
        <Text
          type={TextTypes.TEXT_MD}
          color={Color.gray700}
          className="tw-mb-8"
        >
          Enter the email address of the user you want to invite. Each invite is
          specific to an email address and will expire after <b>7 days</b>.
        </Text>
      )}

      <FormRow>
        {user?.has_sso ? (
          <>
            <ReadOnlyFormValue
              required
              title="First Name"
              value={watch('first_name')}
            />
            <ReadOnlyFormValue
              required
              title="Last Name"
              value={watch('last_name')}
            />
          </>
        ) : (
          <>
            <Input
              {...register('first_name')}
              fullWidth
              required
              label="First Name"
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
            />
            <Input
              {...register('last_name')}
              fullWidth
              required
              label="Last Name"
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
            />
          </>
        )}
      </FormRow>

      {editMode ? (
        <>
          <ReadOnlyFormValue
            required
            biggerGap
            title="Email"
            value={watch('email')}
            className="tw-mt-6"
          />

          <ReadOnlyFormValue
            required
            biggerGap
            title="Status"
            value={
              <Chip
                className="tw-self-start"
                type={disabled ? 'default' : 'success'}
                label={disabled ? 'Inactive' : 'Active'}
              />
            }
            className="tw-mt-6"
          />
        </>
      ) : (
        <Input
          {...register('email')}
          required
          label="Email"
          type="email"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
      )}
      {SsoComponent}
    </Col>
  )
}

export default ProfileDetailsFormSection
