import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import { isNotVisitor } from '@/features/gate/utils'

const Id: FC<ReadOnlyProps> = (props) => {
  const { readOnly, label = 'Driver ID' } = props
  const { control, watch } = useFormContext()

  const driverId = watch(FORM_IDS.DRIVER_DETAILS.DRIVER_ID)
  const appointmentType = watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE)

  const required = isNotVisitor(appointmentType)

  if (readOnly) return <ReadOnlyFormValue title={label} value={driverId} />

  return (
    <Controller
      name={FORM_IDS.DRIVER_DETAILS.DRIVER_ID}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          fullWidth
          uppercase
          required={required}
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default Id
