import clsx from 'clsx'
import { Text } from '@/components/atoms'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { Color } from '@/styles/palette'
import { Link } from 'react-router-dom'
import { MenuItem as MuiMenuItem } from '@mui/material'
import React, { FC, KeyboardEvent, MouseEvent, useCallback } from 'react'
import { DropdownMenuItem } from '@/types/interfaces/ui'
import { onEnterPressed } from '@/utils/helpers'

import styles from './MenuItem.module.scss'

interface Props {
  item: DropdownMenuItem
  linkMode?: boolean
  onClick: (
    e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
    id: string
  ) => void
}

const MenuItem: FC<Props> = (props) => {
  const { item, linkMode, onClick } = props
  const { id, label, icon, hidden, selected, className } = item

  const classes = clsx(styles.menuItem, selected && styles.selected, className)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      onEnterPressed(event, () => onClick(event, event.currentTarget.id))
    },
    [onClick]
  )

  const content = (
    <>
      {icon}

      <Text
        type={TextTypes.TEXT_MD}
        color={Color.gray800}
        weight={selected ? FontWeight.SEMIBOLD : FontWeight.REGULAR}
      >
        {label}
      </Text>
    </>
  )

  return linkMode ? (
    <Link to={`./${id}`} key={id} className={classes}>
      {content}
    </Link>
  ) : (
    <MuiMenuItem
      key={id}
      hidden={hidden}
      onKeyDown={handleKeyDown}
      onClick={(e) => onClick(e, id)}
      className={classes}
    >
      {content}
    </MuiMenuItem>
  )
}

export default MenuItem
