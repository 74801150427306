import dayjs from 'dayjs'

import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  fuelTypesFriendlyNames,
  weightClassesFriendlyNames
} from '@/utils/mappers'
import {
  appointmentTypeToBoolean,
  arrayToString,
  millisecondsToDuration,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'
import friendlyName from '@/utils/table/converters/friendlyName'
import {
  GateTransaction,
  OrderByDomainEventColumn
} from '@/__generated__/graphql'

const driversColumns = (): ITableColumn[] => [
  {
    id: 'eventTime',
    title: 'Check-In Time',
    type: TableColumnType.Date,
    sortable: true,
    sortId: OrderByDomainEventColumn.EventTime,
    defaultVisible: true
  },
  {
    id: 'metadata.appointmentType',
    title: 'Visitor',
    type: TableColumnType.Text,
    converter: appointmentTypeToBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.AppointmentType
  },
  {
    id: 'metadata.powerUnitOwnerId',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitOwnerId
  },
  {
    id: 'metadata.powerUnitCarrierName',
    title: 'Carrier',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitCarrierName,
    defaultVisible: true
  },
  {
    id: 'metadata.accountName',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByDomainEventColumn.AccountName
  },
  {
    id: 'metadata.powerUnitLicensePlateNumber',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitLicensePlateNumber,
    defaultVisible: true
  },
  {
    id: 'metadata.powerUnitLicensePlateState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitLicensePlateState,
    defaultVisible: true
  },
  {
    id: 'metadata.powerUnitCarrierUsdot',
    title: 'USDOT',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitCarrierUsdot
  },
  {
    id: 'metadata.powerUnitWeightClass',
    title: 'Class',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: weightClassesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitWeightClass
  },
  {
    id: 'metadata.powerUnitFuelType',
    title: 'Fuel Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: fuelTypesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitFuelType
  },

  {
    id: 'driverName',
    title: 'Driver Name',
    type: TableColumnType.Text,
    converter: ({ metadata }: GateTransaction) =>
      `${metadata.driverFirstName} ${metadata.driverLastName}`,
    sortable: true,
    sortId: OrderByDomainEventColumn.DriverFirstName,
    defaultVisible: true
  },
  {
    id: 'metadata.driverLicenseNumber',
    title: 'Driver License',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.DriverLicenseNumber
  },
  {
    id: 'metadata.driverPhoneNumber',
    title: 'Driver Phone',
    type: TableColumnType.Phone,
    sortable: true,
    sortId: OrderByDomainEventColumn.DriverPhoneNumber
  },
  {
    id: 'metadata.cargoAssetOwnerId',
    title: 'Arriving Cargo Asset',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetOwnerId
  },
  {
    id: 'dwell',
    title: 'Dwell',
    type: TableColumnType.Text,
    converter: (row: GateTransaction) =>
      millisecondsToDuration({ dwell: dayjs().diff(row.eventTime) }, 'dwell'),
    defaultVisible: true
  },
  {
    id: 'metadata.mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.Mismatch
  }
]

export default driversColumns
