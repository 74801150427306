import { useState } from 'react'

import {
  GateTransaction,
  OrderByDomainEventColumn,
  SearchDomainEventColumns,
  StringOperator
} from '@/__generated__/graphql'
import { prepareSortForGraphQlApi } from '@/api'
import DriversOnSite from '@/assets/icons/drivers_on_site.svg?react'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { useCheckMyPermissionsQuery } from '@/features/auth/api'
import { LaneRelation, PermissionResourceType } from '@/features/auth/enums'
import { preparePermissionResourceQuery } from '@/features/auth/helpers'
import { EditDriverOnsiteModal } from '@/features/gate'
import { GetDriversOnsiteQuery } from '@/features/gate/api/queries'
import { driversColumns } from '@/features/gate/utils'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'
import {
  DEFAULT_TABLE_REQUEST_DETAILS,
  TableRequestDetails
} from '@/types/interfaces/table'
import {
  appointmentTypeFilter,
  dateRangeFilter,
  dwellFilter,
  fuelTypeFilter,
  mismatchFilter,
  weightClassFilter
} from '@/utils/table'
import { useQuery } from '@apollo/client'

const GateDriversTemplate = () => {
  const { selectedPortal, org } = useStore((store) => store.user)
  const { visible, openModal, closeModal } = useModal(false)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [
    { currentPage, search, sortBy, filters, dateRange },
    setRequestDetails
  ] = useState<TableRequestDetails>(DEFAULT_TABLE_REQUEST_DETAILS)
  const [selectedItem, setSelectedItem] = useState<
    GateTransaction | undefined
  >()

  const { loading, data, refetch } = useQuery(GetDriversOnsiteQuery, {
    variables: {
      input: {
        organizationID: org_id,
        siteID: site_id,
        currentPage: currentPage,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
        search: {
          fields: [
            SearchDomainEventColumns.PowerUnitOwnerId,
            SearchDomainEventColumns.PowerUnitLicensePlateNumber,
            SearchDomainEventColumns.PowerUnitCarrierName,
            SearchDomainEventColumns.AccountName,
            SearchDomainEventColumns.PowerUnitCarrierUsdot,
            SearchDomainEventColumns.DriverName,
            SearchDomainEventColumns.DriverLicenseNumber,
            SearchDomainEventColumns.DriverPhoneNumber,
            SearchDomainEventColumns.CargoAssetOwnerId
          ],
          operator: StringOperator.Contains,
          value: search
        },
        order: prepareSortForGraphQlApi<OrderByDomainEventColumn>(sortBy),
        filter: {
          ...(filters || {}),

          // TODO: Remove when BE handles this
          powerUnitLicensePlateState: {
            operator: StringOperator.NotEquals,
            value: ''
          }
        },
        dateRange
      }
    }
  })

  const drivers = data?.listDriversOnSite?.drivers || []
  const laneId = drivers?.[0]?.metadata?.laneId
  const pagination = data?.listDriversOnSite?.pagination

  const { currentData: updatePermission, isFetching } =
    useCheckMyPermissionsQuery(
      {
        orgId: org_id,
        relation: LaneRelation.CreateLaneEvent,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Lane,
          laneId
        )
      },
      { skip: !laneId }
    )

  const isUpdateAllowed = updatePermission?.data?.has_permission

  const handleAction = (action: TableActions, row: GateTransaction) => {
    switch (action) {
      case TableActions.RowClick: {
        if (!isUpdateAllowed) return

        openModal()
        setSelectedItem(row)
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <>
      {visible && (
        <EditDriverOnsiteModal
          item={selectedItem as GateTransaction}
          closeModal={closeModal}
          refetch={refetch}
        />
      )}

      <WithHeaderTemplate title="Drivers On Site">
        <div className="tw-flex-1 tw-flex">
          <Table
            withSearch
            name="drivers"
            rows={drivers}
            loading={loading || isFetching}
            clickable={isUpdateAllowed}
            columns={driversColumns}
            handleAction={handleAction}
            filters={[
              dateRangeFilter,
              appointmentTypeFilter,
              weightClassFilter,
              dwellFilter,
              fuelTypeFilter,
              mismatchFilter
            ]}
            searchPlaceholder="Search Visitor"
            perPage={PAGINATION_ITEMS_PER_PAGE}
            currentPage={currentPage}
            totals={{
              items: pagination?.totalItemsCount,
              pages: pagination?.totalPages
            }}
            onUpdateRequestDetails={setRequestDetails}
            noResultsMessage={{
              primaryText: 'No Drivers on site',
              descriptionText:
                'Drivers that have checked-in but have not checked-out will appear here.',
              Icon: <DriversOnSite />
            }}
          />
        </div>
      </WithHeaderTemplate>
    </>
  )
}

export default GateDriversTemplate
