import { Text } from '@/components/atoms'
import { TextTypes } from '@/types/enums/ui'
import clsx from 'clsx'
import React from 'react'

interface IProps {
  children?: React.ReactNode
  className?: string
}

const SmallTableText = ({ children, className }: IProps) => {
  return (
    <Text
      type={TextTypes.TEXT_SM}
      className={clsx('tw-whitespace-nowrap', className)}
    >
      {children}
    </Text>
  )
}

export default SmallTableText
