import React, { FC } from 'react'

import { Button, Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextAlign, TextTypes } from '@/types/enums/ui'
import clsx from 'clsx'

import styles from './BaseEmptyState.module.scss'

export const DEFAULT_EMPTY_STATE: IBaseEmptyState = {
  primaryText: 'No results found',
  descriptionText: 'Items will appear here once they are added'
}

export interface IBaseEmptyState {
  Icon?: React.ReactNode
  primaryText: string
  descriptionText: string
  buttonText?: string
  className?: string
  buttonAction?: () => void
}

const BaseEmptyState: FC<IBaseEmptyState> = (props) => {
  const {
    Icon,
    primaryText,
    descriptionText,
    buttonText,
    className,
    buttonAction
  } = props

  return (
    <Col
      items="center"
      justify="center"
      gap={24}
      className={clsx(styles.baseEmptyState, className)}
    >
      {Icon}

      <Col items="center" justify="center" gap={16}>
        <Text
          type={TextTypes.TEXT_MD}
          align={TextAlign.CENTER}
          color={Color.gray700}
        >
          {primaryText}
        </Text>

        <Text
          type={TextTypes.TEXT_SM}
          align={TextAlign.CENTER}
          color={Color.gray600}
        >
          {descriptionText}
        </Text>
      </Col>

      {buttonText && buttonAction && (
        <Button type="outlined" onClick={buttonAction}>
          {buttonText}
        </Button>
      )}
    </Col>
  )
}

export default BaseEmptyState
