const AG_GRID_ROOT_ID = '.ag-root'

/**
 * Returns the width of the AgGrid.
 * The grid must be present in the DOM.
 *
 * @returns the width of the AgGrid if present in the Dom. Otherwise, 0.
 */
const getAgGridWidth = () => {
  const gridContainer = document.querySelector(AG_GRID_ROOT_ID)
  const gridWidth = gridContainer ? gridContainer.clientWidth : 0
  return gridWidth
}

export default getAgGridWidth
