import { Loader, Row } from '@/components/atoms'
import { BaseEmptyState } from '@/components/organisms'
import { Queues } from '@/features/gate'
import { useFetchLanesQuery } from '@/features/gate/api'
import { useStore } from '@/store'

const GateQueueTemplate = () => {
  const { org, selectedPortal, selectedGate } = useStore(
    (store) => store.user || {}
  )

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const gate_id = selectedGate?.id || ''

  const { data: lanesResponse, isFetching: loading } = useFetchLanesQuery(
    { org_id, site_id, gate_id },
    { skip: !org_id || !site_id || !gate_id }
  )

  const lanes = !gate_id ? [] : lanesResponse?.data?.lanes || []
  const gatesOrLanes = !gate_id ? 'gates' : 'lanes'

  return (
    <Row items="stretch" className="tw-flex-1">
      {loading || !lanes.length ? (
        <Row
          gap={8}
          items="center"
          justify="center"
          className="tw-w-full tw-h-full"
        >
          {loading ? (
            <Loader />
          ) : (
            <BaseEmptyState
              primaryText={`There are no ${gatesOrLanes}`}
              descriptionText={`The selected site does not contain any ${gatesOrLanes}.`}
            />
          )}
        </Row>
      ) : (
        <Queues lanes={lanes} gateId={gate_id} />
      )}
    </Row>
  )
}

export default GateQueueTemplate
