import React, { useState } from 'react'

import { Text } from '@/components/atoms'
import { FormCol, FormRow, FormSection } from '@/features/forms/components'
import { NameType } from '@/features/forms/enums'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import { Id, LicenseState, Name, Phone } from './fields'
import { FORM_SECTION_ANCHORS } from '@/features/forms/constants'

import styles from './DriverDetailsFormSection.module.scss'

interface IProps {
  title?: string
  readOnly?: boolean
  collapsed?: boolean
  withoutWrapper?: boolean
}

const DriverDetailsFormSection = (props: IProps) => {
  const {
    readOnly,
    withoutWrapper,
    collapsed,
    title = 'Driver Details'
  } = props

  const [selectedDriverId, setSelectedDriverId] = useState<string | undefined>()

  const Fields = (
    <FormCol id={FORM_SECTION_ANCHORS.DRIVER.ID}>
      <FormRow>
        <Name
          selectedDriverId={selectedDriverId}
          setSelectedDriverId={setSelectedDriverId}
          type={NameType.First}
          readOnly={readOnly}
        />
        <Name
          selectedDriverId={selectedDriverId}
          setSelectedDriverId={setSelectedDriverId}
          type={NameType.Last}
          readOnly={readOnly}
        />
      </FormRow>

      <FormRow>
        <Id readOnly={readOnly} />
        <LicenseState readOnly={readOnly} />
      </FormRow>

      <Phone readOnly={readOnly} />
    </FormCol>
  )

  return withoutWrapper ? (
    <>
      <Text
        type={TextTypes.TEXT_MD}
        weight={FontWeight.SEMIBOLD}
        color={Color.gray700}
        className={styles.titleWithBorder}
      >
        {title}
      </Text>

      {Fields}
    </>
  ) : (
    <FormSection title={title} collapsed={collapsed}>
      {Fields}
    </FormSection>
  )
}

DriverDetailsFormSection.Id = Id
DriverDetailsFormSection.Name = Name
DriverDetailsFormSection.Phone = Phone
DriverDetailsFormSection.LicenseState = LicenseState

export default DriverDetailsFormSection
