import { Chip, Text } from '@/components/atoms'
import { ActionsDropdown } from '@/components/molecules'
import { Color } from '@/styles/palette'
import { TableActions, TableColumnType } from '@/types/enums/table'
import { DateFormat, TextTypes } from '@/types/enums/ui'
import {
  ITableActionColumn,
  ITableActionsColumn,
  ITableChipColumn,
  ITableColumn
} from '@/types/interfaces/table'
import { formatDate } from '@/utils/helpers'
import { ICellRendererParams } from 'ag-grid-community'
import clsx from 'clsx'
import { get } from 'lodash'
import SmallTableText from '../Text/SmallTableText'

interface IProps {
  params: ICellRendererParams
  column: ITableColumn
  handleAction?: (action: TableActions, row: any) => void
}

const TableCellRenderer = ({ params, column, handleAction }: IProps) => {
  const value = column.converter
    ? column.converter(params.data, column.id, column.friendlyNameMapper)
    : get(params.data, column.id)

  const className = column.className || ''

  switch (column.type) {
    case TableColumnType.Date:
      return (
        <SmallTableText className={className}>
          {formatDate(value, DateFormat.DATE_TIME)}
        </SmallTableText>
      )

    case TableColumnType.Action:
      return (
        <Text
          type={TextTypes.TEXT_SM}
          color={Color.green500}
          className={clsx(
            'tw-whitespace-nowrap',
            'hover:tw-underline',
            className
          )}
          onClick={(e) => {
            e.stopPropagation()
            handleAction?.(TableActions.ColumnAction, params.data)
          }}
        >
          {(column as ITableActionColumn).actionName}
        </Text>
      )

    case TableColumnType.Actions:
      return (
        <ActionsDropdown
          row={params.data}
          actions={(column as ITableActionsColumn).actions}
          onClick={(action) =>
            handleAction?.(action as TableActions, params.data)
          }
          table
        />
      )

    case TableColumnType.Chip:
      const chipColumn = column as ITableChipColumn
      return (
        <Chip
          size="sm"
          label={chipColumn.getLabel(params.data)}
          type={chipColumn.getVariant(params.data)}
          className={className}
        />
      )

    default:
      return value ? (
        <SmallTableText className={className}>{value}</SmallTableText>
      ) : null
  }
}

export default TableCellRenderer
