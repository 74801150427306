import './Button.scss'

import Button from '@mui/material/Button'
import clsx from 'clsx'
import { forwardRef, ForwardRefRenderFunction } from 'react'

import { CommonButtonProps } from '@/types/interfaces/ui'

type TVariant = 'outlined' | 'contained' | 'text' | undefined
type TColor = 'primary' | 'secondary' | 'info' | 'error' | undefined

const CustomButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  CommonButtonProps
> = (props, ref) => {
  const {
    small,
    href,
    square,
    action,
    type = 'primary',
    onClick,
    keepFocus,
    startIcon,
    endIcon,
    disabled,
    children,
    className
  } = props

  const variant = {
    primary: 'contained',
    secondary: 'contained',
    outlined: 'outlined',
    danger: 'contained',
    text: 'text',
    dangerText: 'text'
  }

  const color = {
    primary: 'primary',
    secondary: 'secondary',
    outlined: 'info',
    danger: 'error',
    text: 'primary',
    dangerText: 'error'
  }

  return (
    <Button
      ref={ref}
      href={href}
      type={action}
      disableRipple
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      color={color[type] as TColor}
      variant={variant[type] as TVariant}
      disableElevation
      className={clsx(
        'button',
        keepFocus && 'focused',
        small && 'small',
        square && 'square',
        className
      )}
    >
      {children}
    </Button>
  )
}

export default forwardRef(CustomButton)
