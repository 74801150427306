import { useStore } from '@/store'
import { useLocalStorage } from 'usehooks-ts'

const TERMINAL_DOMAIN = 'terminal-industries'

const useDevModeMetadata = () => {
  const { me } = useStore((store) => store.user)
  const { email = '' } = me || {}

  const isTerminalUser = email.includes(TERMINAL_DOMAIN)

  const [metadataEnabled, setMetadataEnabled] = useLocalStorage(
    'metadataEnabled',
    false
  )

  return {
    isTerminalUser,
    metadataEnabled: isTerminalUser && metadataEnabled,
    setMetadataEnabled
  }
}

export default useDevModeMetadata
