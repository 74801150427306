export enum TableColumnType {
  Text = 'text',
  Date = 'date',
  Number = 'number',
  Phone = 'phone',
  Actions = 'actions',
  Action = 'action',
  Chip = 'chip'
}

export enum TableFilterType {
  List = 'list',
  DateRange = 'dateRange',
  Dwell = 'dwell'
}

export enum TableActions {
  Delete = 'delete',
  Edit = 'edit',
  Cancel = 'cancel',
  RowClick = 'rowClick',
  ColumnAction = 'columnAction',
  Toggle = 'toggle'
}

export enum TableSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}
