import { ISelectOption } from '@/types/interfaces/ui'

export const DELETE_REASONS: ISelectOption[] = [
  { id: 'DUPLICATE', label: 'Duplicate' },
  { id: 'PERSONAL_VEHICLE', label: 'Personal Vehicle' },
  { id: 'TURNED_AWAY', label: 'Turned Away' }
]

export const VALUE_IS_NOT_FROM_LIST_TYPE = 'not-selected-from-list'

export const LPN_IS_NOT_FROM_LIST_ERROR = {
  type: VALUE_IS_NOT_FROM_LIST_TYPE,
  message: 'Please select LPN from the list.'
}

export const ASSET_ID_IS_NOT_FROM_LIST_ERROR = {
  type: VALUE_IS_NOT_FROM_LIST_TYPE,
  message: 'Please select Asset ID from the list.'
}
