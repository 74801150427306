import { yupResolver } from '@hookform/resolvers/yup'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form'

import { Button } from '@/components/atoms'
import { TwoColumnModalWrapper } from '@/components/molecules'
import {
  DriverDetailsFormSection,
  FormAside,
  ModalWithForm,
  SelectFirstSection
} from '@/features/forms/components'
import { FormSectionType } from '@/features/forms/enums'
import { CheckOutCargoAsset, CheckOutPowerUnit } from '@/features/gate'
import {
  useCreateGateTransactionMutation,
  useGetPresignedUrlQuery
} from '@/features/gate/api'
import {
  CreateGateTransactionRequestBody,
  CreateGateTransactionRequestParams
} from '@/features/gate/api/types'
import {
  DomainEventSchema,
  DomainEventTypes,
  DomainTransactionTypes,
  LaneDirection
} from '@/features/gate/enums'
import { useFormSectionsPresent } from '@/features/gate/hooks'
import { GateQueueEvent, Lane } from '@/features/gate/types'
import {
  GateTransactionSchema,
  GateTransactionSchemaType,
  getFormValuesFromQueueEvent,
  prepareGateTransactionMetadata
} from '@/features/gate/utils'
import { useStore } from '@/store'
import { mergeDateWithTime, scrollToFirstFormError } from '@/utils/helpers'
import { FORM_IDS, YUP_CONTEXT_VARIABLES } from '@/features/forms/constants'
import { GateTransaction } from '@/__generated__/graphql'
import { CheckOutFormContextProvider } from '@/features/gate/contexts'
import {
  ASSET_ID_IS_NOT_FROM_LIST_ERROR,
  LPN_IS_NOT_FROM_LIST_ERROR
} from '@/features/gate/constants'

interface IProps {
  item?: GateQueueEvent
  lane: Lane
  gateId: string
  closeModal: (removeFromMovedItems?: boolean) => void
  onSwapLane: (
    item: GateQueueEvent,
    to: LaneDirection,
    reopenModal?: boolean
  ) => void
}

export interface AvailableDispatchables {
  powerUnits: GateTransaction[]
  cargoAssets: GateTransaction[]
}

const CheckOutModal: FC<IProps> = (props) => {
  const { item, gateId, lane, closeModal, onSwapLane } = props

  const { selectedPortal, org } = useStore((store) => store.user)

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const [availableUnits, setAvailableUnits] = useState<AvailableDispatchables>(
    () => ({
      cargoAssets: [],
      powerUnits: []
    })
  )

  const [createGateTransaction, { isLoading }] =
    useCreateGateTransactionMutation()

  const { data: imageUrl } = useGetPresignedUrlQuery(
    { org_id, site_id, event_id: item?.id || '' },
    { skip: !item }
  )

  const {
    selectSection,
    removeCargoAsset,
    removePowerUnitAndDriver,
    isSectionPresent,
    isEmptyForm
  } = useFormSectionsPresent(item)

  const formReturn = useForm<GateTransactionSchemaType>({
    resolver: yupResolver(GateTransactionSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: getFormValuesFromQueueEvent(item),
    shouldFocusError: false,

    context: {
      [YUP_CONTEXT_VARIABLES.HAS_POWER_UNIT_AND_DRIVER]:
        isSectionPresent.powerUnitAndDriverDetails,
      [YUP_CONTEXT_VARIABLES.HAS_CARGO_ASSET]:
        isSectionPresent.cargoAssetDetails
    }
  })

  const {
    reset,
    watch,
    setError,
    handleSubmit,
    formState: { submitCount, errors }
  } = formReturn

  const isManual = !item

  const onSubmit = async (formData: GateTransactionSchemaType) => {
    if (!org_id || !site_id) return

    const metadata = prepareGateTransactionMetadata({
      formData,
      initialMetadata: {
        lane_id: lane.id,
        transaction_type: DomainTransactionTypes.CheckOut
      },
      hasPowerUnitAndDriver: isSectionPresent.powerUnitAndDriverDetails,
      hasCargoAsset: isSectionPresent.cargoAssetDetails
    })

    const cargoAssetOnly =
      !isSectionPresent.powerUnitAndDriverDetails &&
      isSectionPresent.cargoAssetDetails

    const query: CreateGateTransactionRequestBody = {
      event_time: mergeDateWithTime(formData.date, formData.time).toISOString(),
      reference_id: item?.correlation_id || '',
      transaction_type: DomainTransactionTypes.CheckOut,
      type: cargoAssetOnly
        ? DomainEventTypes.ManualOnsiteCargoAssetCheckOut
        : DomainEventTypes.ManualCheckOut,
      schema: DomainEventSchema.September2024,
      metadata,
      metadata_raw: item?.metadata?.AssetChainEvent || {}
    }

    const params: CreateGateTransactionRequestParams = {
      org_id,
      site_id,
      gate_id: gateId,
      lane_id: lane.id
    }

    const response = await createGateTransaction({
      params,
      body: query
    })

    if (!response.error) {
      closeModal(true)
    }
  }

  const onSubmitError: SubmitErrorHandler<GateTransactionSchemaType> = () => {
    const powerUnitLpn = watch(FORM_IDS.POWER_UNIT.LPN)
    const assetOwnerId = watch(FORM_IDS.CARGO_ASSET.OWNER_ID)

    const isLpnSelectedFromList = availableUnits.powerUnits.some(
      (unit: GateTransaction) =>
        unit.metadata.powerUnitLicensePlateNumber === powerUnitLpn
    )

    const isAssetOwnerSelectedFromList = availableUnits.cargoAssets.some(
      (asset: GateTransaction) =>
        asset.metadata.cargoAssetOwnerId === assetOwnerId
    )

    if (powerUnitLpn && !isLpnSelectedFromList) {
      setError(FORM_IDS.POWER_UNIT.LPN, LPN_IS_NOT_FROM_LIST_ERROR)
    }

    if (assetOwnerId && !isAssetOwnerSelectedFromList) {
      setError(FORM_IDS.CARGO_ASSET.OWNER_ID, ASSET_ID_IS_NOT_FROM_LIST_ERROR)
    }
  }

  const updateAvailablePowerUnits = useCallback(
    (powerUnits: GateTransaction[]) => {
      setAvailableUnits((prev) => ({ ...prev, powerUnits }))
    },
    [setAvailableUnits]
  )

  const updateAvailableCargoAssets = useCallback(
    (cargoAssets: GateTransaction[]) => {
      setAvailableUnits((prev) => ({ ...prev, cargoAssets }))
    },
    [setAvailableUnits]
  )

  useEffect(() => {
    scrollToFirstFormError(errors)
  }, [submitCount])

  const formSections = {
    [FormSectionType.PowerUnitAndDriver]: (
      <>
        <CheckOutPowerUnit
          siteId={site_id}
          isManual={isManual}
          isPresent={isSectionPresent.powerUnitAndDriverDetails}
          onAdd={selectSection}
          onRemove={
            isManual ? () => removePowerUnitAndDriver(reset) : undefined
          }
        />

        {!isManual && <DriverDetailsFormSection readOnly />}
      </>
    ),

    [FormSectionType.CargoAsset]: (
      <CheckOutCargoAsset
        siteId={site_id}
        onAdd={selectSection}
        onRemove={() => removeCargoAsset(reset)}
        isPresent={isSectionPresent.cargoAssetDetails}
      />
    )
  }

  return (
    <FormProvider {...formReturn}>
      <CheckOutFormContextProvider
        setAvailablePowerUnits={updateAvailablePowerUnits}
        setAvailableCargoAssets={updateAvailableCargoAssets}
      >
        <ModalWithForm
          title="Check-Out"
          closeModal={closeModal}
          isLoading={isLoading}
          isSectionPresent={isSectionPresent}
          onAddSection={selectSection}
          submitDisabled={isEmptyForm}
          buttonText="Complete Check-Out"
          onSubmit={handleSubmit(onSubmit, onSubmitError)}
        >
          <TwoColumnModalWrapper
            leftSide={
              <FormAside
                isManual={isManual}
                showImage={!!item}
                image={imageUrl}
                laneName={lane.display_name}
                Action={
                  !!item && (
                    <Button
                      type="outlined"
                      startIcon={<SwapHorizOutlinedIcon />}
                      onClick={() =>
                        onSwapLane(item, LaneDirection.Arriving, true)
                      }
                    >
                      Move to Check-In
                    </Button>
                  )
                }
              />
            }
          >
            <form>
              {isEmptyForm ? (
                <SelectFirstSection
                  direction={LaneDirection.Departing}
                  onSelect={selectSection}
                />
              ) : (
                <>
                  {/* Sections can be added by a user in any order meaning we need to map over  */}
                  {/* the present sections and return their respective components  */}
                  {Object.keys(isSectionPresent).map(
                    (type) => formSections[type as FormSectionType]
                  )}
                </>
              )}
            </form>
          </TwoColumnModalWrapper>
        </ModalWithForm>
      </CheckOutFormContextProvider>
    </FormProvider>
  )
}

export default CheckOutModal
