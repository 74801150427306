import { Col, Loader } from '@/components/atoms'

const ColumnLoading = () => {
  return (
    <Col
      items="center"
      justify="center"
      gap={8}
      className="tw-flex-1 tw-w-full"
    >
      <Loader />
    </Col>
  )
}

export default ColumnLoading
