import { useLazyFetchOrganizationConnectionsQuery } from '@/features/user-management/api'
import { useCallback, useEffect, useState } from 'react'
import { SsoEnabledState } from '../types/sso'
import { useStore } from '@/store'
import { IOrganizationConnection } from '@/features/user-management/api/types'

export function useSsoEnabled() {
  const { org } = useStore((store) => store.user)
  const organizationId = org?.organization_id
  if (!organizationId) return { ssoEnabled: SsoEnabledState.Loading }

  const [fetchOrganizationConnections] =
    useLazyFetchOrganizationConnectionsQuery()

  const [ssoEnabled, setSsoEnabled] = useState<SsoEnabledState>(
    SsoEnabledState.Loading
  )
  const [connections, setConnections] = useState<IOrganizationConnection[]>([])

  const reloadOrganizationConnections = useCallback(async () => {
    if (!organizationId) return
    const listConnectionsResponse = await fetchOrganizationConnections({
      orgId: organizationId
    })

    const data = listConnectionsResponse?.data?.data
    const enabled = data?.sso_enabled
    const connections = data?.connections

    setSsoEnabled(
      enabled ? SsoEnabledState.Enabled : SsoEnabledState.NotEnabled
    )
    setConnections(connections ?? [])
  }, [fetchOrganizationConnections, organizationId])

  useEffect(() => {
    reloadOrganizationConnections()
  }, [reloadOrganizationConnections])

  return {
    ssoEnabled, connections
  }
}
