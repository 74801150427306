export enum LaneDirection {
  Arriving = 'ARRIVING',
  Departing = 'DEPARTING'
}

export enum LaneType {
  Manual = 'MANUAL_GATE_LANE',
  Automatic = 'AUTOMATIC_GATE_LANE'
}

export enum DomainEventTypes {
  ManualCheckIn = 'MANUAL_GATE_CHECK_IN',
  ManualCheckOut = 'MANUAL_GATE_CHECK_OUT',
  ManualOnsiteUpdate = 'MANUAL_ONSITE_UPDATE',
  ManualOnsiteCargoAssetCheckIn = 'MANUAL_ON_SITE_CARGO_ASSET_CHECK_IN',
  ManualOnsiteCargoAssetCheckOut = 'MANUAL_ON_SITE_CARGO_ASSET_CHECK_OUT',
  ManualISRRecordUpdate = 'MANUAL_ISR_RECORD_UPDATE',
  AutomaticGateArrival = 'AUTOMATIC_GATE_ARRIVAL'
}

export enum DomainTransactionTypes {
  CheckIn = 'GATE_CHECK_IN',
  CheckOut = 'GATE_CHECK_OUT'
}

export enum DomainEventSchema {
  September2024 = '2024.09.01'
}

export enum AssetChainType {
  YardSpotter = 'YARD_SPOTTER',
  CommercialNonSeparable = 'COMMERCIAL_NON_SEPARABLE',
  CommercialSeparable = 'COMMERCIAL_SEPARABLE',
  Personal = 'PERSONAL'
}

export enum SealMatchesPW {
  Yes = 'SEALMATCH_YES',
  No = 'SEALMATCH_NO',
  NoSeal = 'SEALMATCH_NOSEAL',
  NoPW = 'SEALMATCH_NOPW'
}

export enum IgnoredAssetTypes {
  YardSpotter = 'YARD_SPOTTER',
  Personal = 'PERSONAL'
}

export enum CameraRole {
  EntranceFront = 'ENTRANCE_FRONT',
  EntranceBack = 'ENTRANCE_BACK'
}

export enum DetectionType {
  Detection = 'detection',
  Manual = 'manual'
}
