import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import clsx from 'clsx'
import { FC, useMemo } from 'react'

import { DropdownMenu, Row } from '@/components/atoms'
import { IAction } from '@/types/interfaces/table'

import { DropdownMenuItem } from '@/types/interfaces/ui'
import styles from './ActionsDropdown.module.scss'

interface IProps {
  table?: boolean
  actions: IAction[]
  row?: any
  onClick: (action: string) => void
}

const ActionsDropdown: FC<IProps> = (props) => {
  const { row, actions, onClick, table = true } = props

  const preparedItems: DropdownMenuItem[] = useMemo(
    () =>
      actions.map((action) => ({
        ...action,
        label:
          typeof action.label === 'function' ? action.label(row) : action.label
      })),
    [actions, row]
  )

  return (
    <DropdownMenu placement="right" items={preparedItems} onClick={onClick}>
      {({ toggle }) => (
        <div
          className={clsx(styles.actions, table && styles.table)}
          onClick={(e) => e.stopPropagation()}
        >
          <Row
            clickable
            tabIndex={0}
            items="center"
            justify="end"
            onClick={(e) => {
              e.stopPropagation()
              toggle()
            }}
            className={styles.actionBtn}
          >
            <MoreHorizIcon />
          </Row>
        </div>
      )}
    </DropdownMenu>
  )
}

export default ActionsDropdown
