import * as yup from 'yup'

export const EditUserSchema = yup.object({
  first_name: yup
    .string()
    .trim()
    .max(20, 'Max length is 50.')
    .required('First Name is required.'),
  last_name: yup
    .string()
    .trim()
    .max(20, 'Max length is 50.')
    .required('Last Name is required.'),
  email: yup
    .string()
    .matches(/^[\w.+-]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email.')
    .required('Email is required.'),
  enterpriseEnabled: yup.boolean().required(),
  siteEnabled: yup.boolean().required(),
  useSso: yup.boolean().default(true),
  isAnyRoleEnabled: yup
    .boolean()
    .default(false)
    .test('isAnyRoleEnabled', 'Role is required.', function () {
      const { enterpriseEnabled, siteEnabled } = this.parent

      return enterpriseEnabled || siteEnabled
    }),
  site_roles: yup.array().when('siteEnabled', {
    is: true,
    then: (schema) =>
      schema
        .of(
          yup.object().shape({
            display_name: yup.string(),
            site_id: yup.string(),
            roles: yup.array().of(
              yup.object().shape({
                role: yup.string().required('Role is required.'),
                access: yup.string().required('Permission is required.')
              })
            )
          })
        )
        .min(1, 'Site is required.')
        .required('Site is required.'),
    otherwise: (schema) => schema
  })
})

export type EditUserSchemaType = yup.InferType<typeof EditUserSchema>
