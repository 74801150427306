import { Popover } from '@mui/material'
import React, {
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useRef,
  useState
} from 'react'
import { DropdownMenuItem } from '@/types/interfaces/ui'
import { MenuItem } from '@/components/atoms'
import { PopoverOrigin } from '@mui/material/Popover/Popover'

import styles from './DropdownMenu.module.scss'

interface ChildrenProps {
  open: boolean
  toggle: () => void
}

interface Props {
  linkMode?: boolean
  children: FC<ChildrenProps>
  items: DropdownMenuItem[]
  onClick?: (id: string) => void
  placement?: PopoverOrigin['horizontal']
}

const DropdownMenu: FC<Props> = (props) => {
  const { children, linkMode, items, onClick, placement = 'center' } = props

  const [open, setOpen] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const toggle = useCallback(() => setOpen((prev) => !prev), [])

  const onClickCustom = useCallback(
    (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, id: string) => {
      e.stopPropagation()
      onClick?.(id)
      toggle()
    },
    [onClick]
  )

  return (
    <div ref={containerRef}>
      {children({ open, toggle })}

      <Popover
        open={open}
        onClose={toggle}
        disablePortal={false}
        anchorEl={containerRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: placement
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: placement
        }}
        classes={{ paper: styles.dropdownMenuPaper }}
      >
        {items.map((item) => (
          <MenuItem item={item} linkMode={linkMode} onClick={onClickCustom} />
        ))}
      </Popover>
    </div>
  )
}

export default DropdownMenu
