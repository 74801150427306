import { sanityClient } from '@/services/sanity/client'
import getEnvironment from '@/utils/helpers/getNodeEnvironment'
import { useEffect, useState } from 'react'

interface Banner {
  description: string
  enabled: boolean
  environment: string
}

const useEnvironmentBanner = () => {
  const [banner, setBanner] = useState<Banner | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const environment = getEnvironment()

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const result = await sanityClient.fetch(
          '*[_type == "banner" && environment == $env]',
          { env: environment }
        )

        if (result.length > 1) {
          console.warn(
            `Found more than one banner for environment ${environment}`
          )
        }

        setBanner(result[0])
      } catch (err: any) {
        setError(err.message || 'An error occurred')
      } finally {
        setLoading(false)
      }
    }

    fetchBanners()
  }, [environment])

  return { banner, loading, error }
}

export default useEnvironmentBanner
