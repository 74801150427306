import { ColumnState, GridApi } from 'ag-grid-community'
import { ManageColumnState } from '../../components/TableFilters/ManagedColumnState'
import autosizeVisibleAgGridColumns from './autosizeVisibleAgGridColumns'

const toggleColumnVisibilities = (
  api: GridApi,
  newStates: ManageColumnState[],
  existingStates: ColumnState[]
) => {
  const orderedIds = newStates.map((newState) => newState.colId)

  const finalState = existingStates
    .map((state) => ({
      ...state,
      hide: newStates.find((newState) => newState.colId === state.colId)?.hidden
    }))
    .sort((a, b) => {
      return orderedIds.indexOf(a.colId) - orderedIds.indexOf(b.colId)
    })

  api.applyColumnState({
    state: finalState,
    applyOrder: true
  })

  autosizeVisibleAgGridColumns(api)
}

export default toggleColumnVisibilities
