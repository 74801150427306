import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  Col,
  Input,
  ReadOnlyFormValue,
  Row,
  Switch,
  Text
} from '@/components/atoms'
import { WithHeaderTemplate } from '@/components/templates'
import { changeUserName } from '@/features/auth/store'
import { ProfileSchema } from '@/features/profile/utils'
import { useSnackbar } from '@/features/snackbars-queue'
import {
  useFetchProfileQuery,
  useUpdateUserDetailsMutation
} from '@/features/user-management/api'
import { useDispatch, useStore } from '@/store'
import { Color } from '@/styles/palette'
import { useDevModeMetadata } from '@/hooks'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './ProfileTemplate.module.scss'

const ProfileTemplate = () => {
  const { me, org } = useStore((store) => store.user)
  const dispatch = useDispatch()
  const { showSuccessSnackbar } = useSnackbar()
  const { isTerminalUser, metadataEnabled, setMetadataEnabled } =
    useDevModeMetadata()

  const { first_name = '', last_name = '', email = '-' } = me || {}

  const [updateUser, { isLoading }] = useUpdateUserDetailsMutation()

  const { data, isFetching } = useFetchProfileQuery({
    orgId: org?.organization_id!,
    userId: me?.user_id!
  })
  const hasSso = data?.data?.has_sso || false

  const {
    register,
    getValues,
    reset,
    formState: { errors, isValid, isDirty }
  } = useForm({
    resolver: yupResolver(ProfileSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      firstName: first_name,
      lastName: last_name
    }
  })

  const onSave = async () => {
    if (!me?.user_id || !org?.organization_id) return

    const values = getValues()

    const response = await updateUser({
      userId: me.user_id,
      orgId: org.organization_id,

      first_name: values.firstName,
      last_name: values.lastName,
      display_name: `${values.firstName} ${values.lastName}`
    })

    if (response?.data?.data?.user_id) {
      dispatch(
        changeUserName({
          first_name: values.firstName,
          last_name: values.lastName,
          name: `${values.firstName} ${values.lastName}`
        })
      )

      reset(values)
      showSuccessSnackbar('Your changes have been saved.')
    }
  }

  return (
    <WithHeaderTemplate title="Account">
      <Col gap={16} className={styles.profileWrapper}>
        <Text
          type={TextTypes.TEXT_MD}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          Your Profile
        </Text>

        <Row gap={16} className={styles.mainInfoHolder}>
          {hasSso ? (
            <>
              <ReadOnlyFormValue
                required
                title="First Name"
                value={first_name}
              />
              <ReadOnlyFormValue required title="Last Name" value={last_name} />
            </>
          ) : (
            <>
              <Input
                {...register('firstName')}
                fullWidth
                required
                label="First Name"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
              <Input
                {...register('lastName')}
                required
                fullWidth
                label="Last Name"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            </>
          )}
        </Row>

        <ReadOnlyFormValue
          required
          biggerGap
          title="Email"
          value={email}
          className="tw-mt-6"
        />

        {isTerminalUser && (
          <Switch
            small
            value={metadataEnabled}
            label="Display VE metadata"
            onChange={() => setMetadataEnabled(!metadataEnabled)}
          />
        )}

        <Button
          type="primary"
          className="tw-self-start"
          disabled={!isDirty || !isValid || isLoading || isFetching}
          onClick={onSave}
        >
          {isLoading ? 'Saving...' : 'Save Changes'}
        </Button>
      </Col>
    </WithHeaderTemplate>
  )
}

export default ProfileTemplate
