import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { useWindowSize } from 'usehooks-ts'
import { Controller, useFormContext } from 'react-hook-form'

import { Col, Input, Portal, ReadOnlyFormValue } from '@/components/atoms'
import {
  FormRow,
  FormSection,
  TruckImageWithLoader
} from '@/features/forms/components'
import { FORM_IDS, FORM_SECTION_ANCHORS } from '@/features/forms/constants'
import { DateFormat } from '@/types/enums/ui'
import { formatDate } from '@/utils/helpers'
import { BREAKPOINTS } from '@/constants'
import { FORM_SECTION_PORTAL_ID } from '@/features/forms/components/FormSection/FormSection'

import styles from './FormAside.module.scss'

interface Props {
  image: string | undefined
  laneName: string
  showImage: boolean
  isManual?: boolean
  Action?: ReactNode
}

const FormAside: FC<Props> = (props) => {
  const { showImage, image, laneName, isManual = false, Action } = props

  const { watch, control } = useFormContext() || {}
  const { width } = useWindowSize()

  const isSmallView = width <= BREAKPOINTS.SM

  const date = watch(FORM_IDS.GENERAL.DATE)
  const time = watch(FORM_IDS.GENERAL.TIME)

  return (
    <FormSection
      onlyCollapsibleOnMobile
      id={FORM_SECTION_ANCHORS.GENERAL.ID}
      title="General"
    >
      <Col
        gap={16}
        className={clsx(styles.formAside, isManual && styles.withoutImage)}
      >
        <Portal wrapperId={FORM_SECTION_PORTAL_ID} disabled={!isSmallView}>
          <TruckImageWithLoader
            title={laneName}
            showImage={showImage}
            image={image}
            className={styles.truckImage}
          />
        </Portal>

        <Col gap={16} className={styles.fieldsWrapper}>
          <FormRow className={styles.dateAndTime}>
            {isManual ? (
              <>
                <Controller
                  control={control}
                  name={FORM_IDS.GENERAL.DATE}
                  render={({ fieldState, field }) => (
                    <Input
                      {...field}
                      required
                      fullWidth
                      type="date"
                      label="Date"
                      endIcon={<EventOutlinedIcon />}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={FORM_IDS.GENERAL.TIME}
                  render={({ fieldState, field }) => (
                    <Input
                      {...field}
                      required
                      fullWidth
                      type="time"
                      label="Time"
                      minutesStep={1}
                      endIcon={<AccessTimeOutlinedIcon />}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </>
            ) : (
              <>
                <ReadOnlyFormValue
                  title="Date"
                  value={formatDate(date, DateFormat.DATE)}
                />
                <ReadOnlyFormValue
                  title="Time"
                  value={formatDate(time, DateFormat.TIME)}
                />
              </>
            )}
          </FormRow>

          <FormRow className={styles.laneAndActions}>
            <ReadOnlyFormValue title="Lane" value={laneName} />

            {!!Action && <div className={styles.actionWrapper}>{Action}</div>}
          </FormRow>
        </Col>
      </Col>
    </FormSection>
  )
}

export default FormAside
