import dayjs from 'dayjs'
import { GateTransactionSchemaType } from '@/features/gate/utils'
import { sealMatchesMapper } from '@/utils/mappers'
import { GateTransaction } from '@/__generated__/graphql'
import {
  AppointmentTypes,
  CargoAssetTypes,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'
import { SealMatchesPW } from '@/features/gate/enums'

const getFormValuesFromGateTransaction = (
  transaction: GateTransaction
): GateTransactionSchemaType => {
  const { eventTime, metadata } = transaction
  const {
    appointmentType,

    powerUnitType,
    powerUnitOwnerId,
    powerUnitLicensePlateNumber,
    powerUnitLicensePlateState,
    powerUnitCarrierName,
    powerUnitCarrierUsdot,
    powerUnitVin,
    powerUnitWeightClass,
    powerUnitFuelType,
    accountName,

    driverFirstName,
    driverLastName,
    driverLicenseNumber,
    driverPhoneNumber,
    driverLicenseState,

    cargoAssetAssetType,
    cargoAssetOwnerId,
    cargoAssetCarrierName,
    chassisId,
    cargoAssetLicensePlateNumber,
    cargoAssetLicensePlateState,
    loadStatus,
    shipmentNumber,

    sealNumbers,
    sealMatchPW,
    inspectionCompleted
  } = metadata

  return {
    date: dayjs(eventTime),
    time: dayjs(eventTime),

    appointment_type: appointmentType as AppointmentTypes,

    power_unit_type: powerUnitType as PowerUnitTypes,
    power_unit_owner_id: powerUnitOwnerId,
    power_unit_license_plate_number: powerUnitLicensePlateNumber,
    power_unit_license_plate_state: powerUnitLicensePlateState,
    power_unit_carrier_name: powerUnitCarrierName,

    power_unit_carrier_usdot: powerUnitCarrierUsdot,
    power_unit_vin: powerUnitVin,
    power_unit_weight_class: powerUnitWeightClass as WeightClasses,
    power_unit_fuel_type: powerUnitFuelType as FuelTypes,
    account_name: accountName || [],

    driver_first_name: driverFirstName,
    driver_last_name: driverLastName,
    driver_license_number: driverLicenseNumber,
    driver_phone_number: driverPhoneNumber,
    driver_license_state: driverLicenseState,

    cargo_asset_asset_type: cargoAssetAssetType as CargoAssetTypes,
    cargo_asset_owner_id: cargoAssetOwnerId,
    cargo_asset_carrier_name: cargoAssetCarrierName,
    chassis_id: chassisId,
    cargo_asset_license_plate_number: cargoAssetLicensePlateNumber,
    cargo_asset_license_plate_state: cargoAssetLicensePlateState,
    load_status: loadStatus as LoadTypes,
    shipment_number: shipmentNumber,
    seal_numbers: sealNumbers || [],
    seal_matchPW: sealMatchesMapper[sealMatchPW as SealMatchesPW],
    inspection_completed: inspectionCompleted
  }
}

export default getFormValuesFromGateTransaction
