import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState
} from 'react'
import { useFormContext } from 'react-hook-form'

import { AddFormSectionButton, FormSection } from '@/features/forms/components'
import { FORM_IDS, FORM_SECTION_ANCHORS } from '@/features/forms/constants'
import { FormSectionType } from '@/features/forms/enums'
import { FailedToLoadFields } from '@/features/forms/types'
import { getMissingFieldNames, isNotVisitor } from '@/features/gate/utils'

import {
  Account,
  VisitorToggle,
  FuelType,
  LicensePlateNumber,
  LicensePlateState,
  OperatingCarrier,
  OwnerId,
  Type,
  UsDOT,
  VinNumber,
  WeightClass
} from './fields'

interface IChildrenProps {
  showFuelTypeAndWeightClass: boolean
  isNotVisitor: boolean
  warningMessage: string | undefined
  setFieldsFailedToLoad: Dispatch<SetStateAction<FailedToLoadFields>>
}

interface IProps {
  title?: string
  isPresent?: boolean
  collapsed?: boolean
  onAdd?: (type: FormSectionType) => void
  onRemove?: () => void
  children: FC<IChildrenProps> | ReactNode
  TitleSuffix?: ReactNode
}

const PowerUnitFormSection = (props: IProps) => {
  const {
    children,
    collapsed,
    TitleSuffix,
    isPresent = true,
    title = 'Power Unit',
    onAdd,
    onRemove
  } = props
  const {
    watch,
    formState: { dirtyFields }
  } = useFormContext()

  const [fieldsFailedToLoad, setFieldsFailedToLoad] =
    useState<FailedToLoadFields>({
      fuelType: false,
      weightClass: false
    })

  const appointmentType = watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE)

  const isVinPresent = !!watch(FORM_IDS.POWER_UNIT.VIN)
  const isVinDirty = !!dirtyFields[FORM_IDS.POWER_UNIT.VIN]

  const childrenProps = useMemo(() => {
    const fieldNames = getMissingFieldNames(fieldsFailedToLoad)
    const showFuelTypeAndWeightClass = isVinPresent || isVinDirty

    return {
      setFieldsFailedToLoad,

      showFuelTypeAndWeightClass,
      isNotVisitor: isNotVisitor(appointmentType),
      warningMessage:
        fieldNames &&
        `Details couldn't be autofilled. Please enter ${fieldNames}`
    }
  }, [
    isVinPresent,
    isVinDirty,
    appointmentType,
    fieldsFailedToLoad,
    setFieldsFailedToLoad
  ])

  if (isPresent) {
    return (
      <FormSection
        id={FORM_SECTION_ANCHORS.POWER_UNIT.ID}
        title={title}
        onRemove={onRemove}
        collapsed={collapsed}
        TitleSuffix={TitleSuffix}
      >
        {typeof children === 'function' ? children(childrenProps) : children}
      </FormSection>
    )
  }

  return onAdd ? (
    <AddFormSectionButton
      type={FormSectionType.PowerUnitAndDriver}
      onClick={onAdd}
    />
  ) : null
}

PowerUnitFormSection.VisitorToggle = VisitorToggle
PowerUnitFormSection.Type = Type
PowerUnitFormSection.UsDOT = UsDOT
PowerUnitFormSection.OwnerId = OwnerId
PowerUnitFormSection.Account = Account
PowerUnitFormSection.FuelType = FuelType
PowerUnitFormSection.VinNumber = VinNumber
PowerUnitFormSection.WeightClass = WeightClass
PowerUnitFormSection.OperatingCarrier = OperatingCarrier
PowerUnitFormSection.LicensePlateState = LicensePlateState
PowerUnitFormSection.LicensePlateNumber = LicensePlateNumber

export default PowerUnitFormSection
