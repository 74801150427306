import { FORM_IDS, YUP_CONTEXT_VARIABLES } from '@/features/forms/constants'
import { getYupVariableKey } from '@/features/forms/utils/index'
import {
  isAssetEmpty,
  isCargoAssetAllowed,
  isCargoAssetMinimized,
  isChassisIdVisible,
  isNotVisitor
} from '@/features/gate/utils'
import {
  AppointmentTypes,
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'

const HAS_POWER_UNIT_AND_DRIVER = getYupVariableKey(
  YUP_CONTEXT_VARIABLES.HAS_POWER_UNIT_AND_DRIVER
)
const HAS_CARGO_ASSET = getYupVariableKey(YUP_CONTEXT_VARIABLES.HAS_CARGO_ASSET)

export const cargoAssetValidations = {
  isAllowed: {
    keys: [
      HAS_CARGO_ASSET,
      FORM_IDS.GENERAL.APPOINTMENT_TYPE,
      FORM_IDS.POWER_UNIT.TYPE
    ],
    validate: (
      hasCargoAsset: boolean,
      appointmentType: AppointmentTypes,
      powerUnitType: PowerUnitTypes
    ) => hasCargoAsset && isCargoAssetAllowed(appointmentType, powerUnitType)
  },

  isNotMinimized: {
    keys: [
      HAS_CARGO_ASSET,
      FORM_IDS.GENERAL.APPOINTMENT_TYPE,
      FORM_IDS.POWER_UNIT.TYPE
    ],
    validate: (
      hasCargoAsset: boolean,
      appointmentType: AppointmentTypes,
      powerUnitType: PowerUnitTypes
    ) =>
      cargoAssetValidations.isAllowed.validate(
        hasCargoAsset,
        appointmentType,
        powerUnitType
      ) && !isCargoAssetMinimized(powerUnitType)
  },

  isNotEmpty: {
    keys: [
      HAS_CARGO_ASSET,
      FORM_IDS.GENERAL.APPOINTMENT_TYPE,
      FORM_IDS.POWER_UNIT.TYPE,
      FORM_IDS.CARGO_ASSET.LOAD_STATUS
    ],
    validate: (
      hasCargoAsset: boolean,
      appointmentType: AppointmentTypes,
      powerUnitType: PowerUnitTypes,
      loadStatus: LoadTypes
    ) =>
      cargoAssetValidations.isAllowed.validate(
        hasCargoAsset,
        appointmentType,
        powerUnitType
      ) && !isAssetEmpty(loadStatus)
  },

  isChassisVisible: {
    keys: [
      HAS_CARGO_ASSET,
      FORM_IDS.GENERAL.APPOINTMENT_TYPE,
      FORM_IDS.POWER_UNIT.TYPE,
      FORM_IDS.CARGO_ASSET.TYPE
    ],
    validate: (
      hasCargoAsset: boolean,
      appointmentType: AppointmentTypes,
      powerUnitType: PowerUnitTypes,
      assetType: CargoAssetTypes
    ) =>
      cargoAssetValidations.isAllowed.validate(
        hasCargoAsset,
        appointmentType,
        powerUnitType
      ) && isChassisIdVisible(powerUnitType, assetType)
  }
}

export const powerUnitValidations = {
  isAdded: {
    keys: [HAS_POWER_UNIT_AND_DRIVER],
    validate: (hasPowerUnitAndDriver: boolean) => hasPowerUnitAndDriver
  },

  isNotVisitor: {
    keys: [FORM_IDS.GENERAL.APPOINTMENT_TYPE],
    validate: isNotVisitor
  },

  isAddedAndNotVisitor: {
    keys: [HAS_POWER_UNIT_AND_DRIVER, FORM_IDS.GENERAL.APPOINTMENT_TYPE],
    validate: (
      hasPowerUnitAndDriver: boolean,
      appointmentType: AppointmentTypes
    ) =>
      powerUnitValidations.isAdded.validate(hasPowerUnitAndDriver) &&
      powerUnitValidations.isNotVisitor.validate(appointmentType)
  }
}
