import { Switch } from '@/components/atoms'
import { useFormContext } from 'react-hook-form'
import { FORM_IDS } from '@/features/forms/constants'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { FC } from 'react'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import { triggerValidationIfError } from '@/features/forms/utils'

const VisitorToggle: FC<ReadOnlyProps> = (props) => {
  const {
    watch,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext()

  const { readOnly, label = 'Check-in as visitor' } = props

  const isVisitor =
    watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE) === AppointmentTypes.Visitor

  const onChange = (newValue: boolean) => {
    if (readOnly) return

    setValue(
      FORM_IDS.GENERAL.APPOINTMENT_TYPE,
      newValue ? AppointmentTypes.Visitor : AppointmentTypes.Scheduled
    )

    triggerValidationIfError(
      [
        FORM_IDS.POWER_UNIT.USDOT,
        FORM_IDS.POWER_UNIT.CARRIER,
        FORM_IDS.DRIVER_DETAILS.DRIVER_ID,
        FORM_IDS.DRIVER_DETAILS.LICENSE_STATE
      ],
      errors,
      trigger
    )
  }

  return (
    <Switch
      disabled={readOnly}
      label={label}
      value={isVisitor}
      onChange={onChange}
    />
  )
}

export default VisitorToggle
