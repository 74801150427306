import { NavigateFunction, useNavigate } from 'react-router-dom'

import { withParams } from '@/api/helpers'
import ROUTES from '@/router/routes'

class Navigator {
  navigate: NavigateFunction

  readonly siteId: string
  readonly gateId: string

  constructor(navigate: NavigateFunction, siteId = '', gateId = '') {
    this.navigate = navigate

    this.siteId = siteId
    this.gateId = gateId
  }

  public to = (path: string) => {
    this.navigate(path)
  }

  public toHome = () => {
    this.navigate(ROUTES.HOME)
  }

  public toGateQueue = () => {
    this.navigateWithParams(ROUTES.GATE_QUEUE)
  }

  public toGateDrivers = () => {
    this.navigateWithParams(ROUTES.GATE_DRIVERS)
  }

  public toGateTransactions = () => {
    this.navigateWithParams(ROUTES.GATE_TRANSACTIONS)
  }

  public toCargoAssets = () => {
    this.navigateWithParams(ROUTES.YARD_CARGO_ASSET)
  }

  public toISR = () => {
    this.navigateWithParams(ROUTES.ISR)
  }

  public toMismatches = () => {
    this.navigateWithParams(ROUTES.ADMIN_MISMATCHES)
  }

  public toVideo = () => {
    this.navigateWithParams(ROUTES.ADMIN_VIDEO)
  }

  public toUsers = () => {
    this.navigateWithParams(ROUTES.USERS)
  }

  public toPendingInvites = () => {
    this.navigateWithParams(ROUTES.PENDING_INVITES)
  }

  public toProfile = () => {
    this.navigate(ROUTES.PROFILE)
  }

  public goBack = () => {
    this.navigate(-1)
  }

  private navigateWithParams(url: string) {
    this.navigate(
      withParams(url, { site_id: this.siteId, gate_id: this.gateId })
    )
  }
}

export function useNavigator(siteId = '', gateId = '') {
  const navigate = useNavigate()

  return new Navigator(navigate, siteId, gateId)
}
