import { Checkbox } from '@/components/atoms'
import { ITableColumn } from '@/types/interfaces/table'
import { Dispatch, SetStateAction } from 'react'
import { ManageColumnState } from './ManagedColumnState'

import styles from './ManageColumnCheckbox.module.scss'

interface IProps {
  column: ITableColumn
  managedColumnsIntermediaryState: ManageColumnState[]
  setManageColumnsIntermediaryState: Dispatch<
    SetStateAction<ManageColumnState[]>
  >
}

const ManageColumnCheckbox = ({
  column,
  managedColumnsIntermediaryState,
  setManageColumnsIntermediaryState
}: IProps) => {
  const hidden = managedColumnsIntermediaryState.find(
    (state) => state.colId === column.id
  )?.hidden

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked

    setManageColumnsIntermediaryState((prevState) => {
      const newColumnState = prevState.map((state) => {
        if (state.colId === column.id) {
          return { ...state, hidden: !checked }
        }
        return state
      })
      return newColumnState
    })
  }

  return (
    <Checkbox
      key={column.id}
      label={column.title}
      value={!hidden}
      onChange={onChange}
      rootClassName={styles.manageCheckbox}
    />
  )
}

export default ManageColumnCheckbox
