import { matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'

import { MAX_LENGTHS, MIN_LENGTHS } from '@/features/forms/constants'
import { FormLengthErrorType } from '@/features/forms/enums'
import {
  cargoAssetValidations,
  lengthErrorMessage,
  powerUnitValidations
} from '@/features/forms/utils'
import {
  AppointmentTypes,
  CargoAssetTypes,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'
import { hasDuplicates } from '@/utils/helpers'
import dayjs from 'dayjs'

export const GateTransactionSchema = yup.object({
  // General Section
  date: yup
    .mixed<dayjs.Dayjs>()
    .test(
      'is-valid-date',
      'Invalid date.',
      (value) => value?.isValid?.() || false
    )
    .required('Date is required.'),
  time: yup
    .mixed<dayjs.Dayjs>()
    .test(
      'is-valid-time',
      'Invalid time.',
      (value) => value?.isValid?.() || false
    )
    .required('Time is required.'),

  // Power Unit Section
  appointment_type: yup
    .mixed<AppointmentTypes>()
    .oneOf(Object.values(AppointmentTypes))
    .required('Appointment Type is required.'),
  power_unit_owner_id: yup.string().when(powerUnitValidations.isAdded.keys, {
    is: powerUnitValidations.isAdded.validate,
    then: (schema) =>
      schema.max(
        MAX_LENGTHS.OWNER_ID,
        lengthErrorMessage(MAX_LENGTHS.OWNER_ID)
      ),
    otherwise: (schema) => schema
  }),
  power_unit_type: yup
    .mixed<PowerUnitTypes>()
    .when(powerUnitValidations.isAdded.keys, {
      is: powerUnitValidations.isAdded.validate,
      then: (schema) =>
        schema
          .oneOf(Object.values(PowerUnitTypes))
          .required('Type is required.'),
      otherwise: (schema) => schema
    }),
  power_unit_license_plate_number: yup
    .string()
    .when(powerUnitValidations.isAdded.keys, {
      is: powerUnitValidations.isAdded.validate,
      then: (schema) =>
        schema
          .trim()
          .min(MIN_LENGTHS.LPN, 'LPN is required.')
          .max(MAX_LENGTHS.LPN, lengthErrorMessage(MAX_LENGTHS.LPN))
          .required('LPN is required.'),
      otherwise: (schema) => schema
    }),
  power_unit_license_plate_state: yup
    .string()
    .when(powerUnitValidations.isAdded.keys, {
      is: powerUnitValidations.isAdded.validate,
      then: (schema) => schema.required('State is required.'),
      otherwise: (schema) => schema
    }),

  power_unit_carrier_name: yup
    .string()
    .when(powerUnitValidations.isAddedAndNotVisitor.keys, {
      is: powerUnitValidations.isAddedAndNotVisitor.validate,
      then: (schema) => schema.required('Carrier is required.'),
      otherwise: (schema) => schema
    }),
  power_unit_carrier_usdot: yup
    .string()
    .when(powerUnitValidations.isAddedAndNotVisitor.keys, {
      is: powerUnitValidations.isAddedAndNotVisitor.validate,
      then: (schema) =>
        schema
          .test(
            'len',
            lengthErrorMessage(MIN_LENGTHS.USDOT, FormLengthErrorType.Min),
            (val) => !!val && val?.toString()?.length >= MIN_LENGTHS.USDOT
          )
          .test(
            'len',
            lengthErrorMessage(MAX_LENGTHS.USDOT),
            (val) => !!val && val?.toString()?.length <= MAX_LENGTHS.USDOT
          )
          .required('US DOT # is required.'),
      otherwise: (schema) => schema
    }),
  power_unit_vin: yup.string().when(powerUnitValidations.isAdded.keys, {
    is: powerUnitValidations.isAdded.validate,
    then: (schema) =>
      schema.max(MAX_LENGTHS.VIN, lengthErrorMessage(MAX_LENGTHS.VIN)),
    otherwise: (schema) => schema
  }),

  power_unit_weight_class: yup
    .mixed<WeightClasses>()
    .when(powerUnitValidations.isAdded.keys, {
      is: powerUnitValidations.isAdded.validate,
      then: (schema) =>
        schema
          .oneOf(Object.values(WeightClasses), 'Class is required.')
          .required('Class is required.'),
      otherwise: (schema) => schema
    }),
  power_unit_fuel_type: yup
    .mixed<FuelTypes>()
    .when(powerUnitValidations.isAdded.keys, {
      is: powerUnitValidations.isAdded.validate,
      then: (schema) =>
        schema
          .oneOf(Object.values(FuelTypes), 'Fuel Type is required.')
          .required('Fuel Type is required.'),
      otherwise: (schema) => schema
    }),
  account_name: yup.array().of(yup.string()),

  // Driver Section
  driver_first_name: yup.string().when(powerUnitValidations.isAdded.keys, {
    is: powerUnitValidations.isAdded.validate,
    then: (schema) =>
      schema
        .max(
          MAX_LENGTHS.DRIVER_NAME,
          lengthErrorMessage(MAX_LENGTHS.DRIVER_NAME)
        )
        .required('First Name is required.'),
    otherwise: (schema) => schema
  }),
  driver_last_name: yup.string().when(powerUnitValidations.isAdded.keys, {
    is: powerUnitValidations.isAdded.validate,
    then: (schema) =>
      schema
        .max(
          MAX_LENGTHS.DRIVER_NAME,
          lengthErrorMessage(MAX_LENGTHS.DRIVER_NAME)
        )
        .required('Last Name is required.'),
    otherwise: (schema) => schema
  }),
  driver_license_number: yup
    .string()
    .when(powerUnitValidations.isAddedAndNotVisitor.keys, {
      is: powerUnitValidations.isAddedAndNotVisitor.validate,
      then: (schema) =>
        schema
          .max(
            MAX_LENGTHS.DRIVER_LICENSE_NUMBER,
            lengthErrorMessage(MAX_LENGTHS.DRIVER_LICENSE_NUMBER)
          )
          .required('Driver License is required.'),
      otherwise: (schema) => schema
    }),
  driver_phone_number: yup
    .string()
    .when(powerUnitValidations.isAdded.keys, {
      is: powerUnitValidations.isAdded.validate,
      then: (schema) =>
        schema.test('format', 'Not valid phone number.', (val) =>
          val
            ? matchIsValidTel(val, {
                onlyCountries: ['US', 'MX', 'CA']
              })
            : true
        ),
      otherwise: (schema) => schema
    })
    .notRequired(),
  driver_license_state: yup
    .string()
    .when(powerUnitValidations.isAddedAndNotVisitor.keys, {
      is: powerUnitValidations.isAddedAndNotVisitor.validate,
      then: (schema) => schema.required('State is required.'),
      otherwise: (schema) => schema
    }),

  // Cargo Asset Section
  cargo_asset_asset_type: yup
    .mixed<CargoAssetTypes>()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) =>
        schema
          .oneOf(Object.values(CargoAssetTypes))
          .required('Asset type is required.'),
      otherwise: (schema) => schema
    }),
  cargo_asset_owner_id: yup
    .string()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) =>
        schema
          .max(MAX_LENGTHS.OWNER_ID, lengthErrorMessage(MAX_LENGTHS.OWNER_ID))
          .required('ID is required.'),
      otherwise: (schema) => schema
    }),
  cargo_asset_carrier_name: yup.string(),
  chassis_id: yup.string().when(cargoAssetValidations.isChassisVisible.keys, {
    is: cargoAssetValidations.isChassisVisible.validate,
    then: (schema) =>
      schema.max(
        MAX_LENGTHS.CHASSIS_ID,
        lengthErrorMessage(MAX_LENGTHS.CHASSIS_ID)
      ),
    otherwise: (schema) => schema
  }),
  cargo_asset_license_plate_number: yup
    .string()
    .trim()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) =>
        schema.max(MAX_LENGTHS.LPN, lengthErrorMessage(MAX_LENGTHS.LPN)),
      otherwise: (schema) => schema
    }),
  cargo_asset_license_plate_state: yup.string(),
  load_status: yup
    .mixed<LoadTypes>()
    .when(cargoAssetValidations.isAllowed.keys, {
      is: cargoAssetValidations.isAllowed.validate,
      then: (schema) =>
        schema
          .oneOf(Object.values(LoadTypes))
          .required('Load Status is required.'),
      otherwise: (schema) => schema
    }),
  shipment_number: yup.string().when(cargoAssetValidations.isNotEmpty.keys, {
    is: cargoAssetValidations.isNotEmpty.validate,
    then: (schema) =>
      schema.max(
        MAX_LENGTHS.SHIPMENT_NUMBER,
        lengthErrorMessage(MAX_LENGTHS.SHIPMENT_NUMBER)
      ),
    otherwise: (schema) => schema
  }),
  seal_numbers: yup.array().when(cargoAssetValidations.isNotEmpty.keys, {
    is: cargoAssetValidations.isNotEmpty.validate,
    then: (schema) =>
      schema
        .of(
          yup
            .string()
            .max(
              MAX_LENGTHS.SEAL_NUMBER,
              lengthErrorMessage(MAX_LENGTHS.SEAL_NUMBER)
            )
        )
        .test(
          'unique',
          'Seal # must be unique.',
          (value) => !hasDuplicates<string>(value as string[])
        ),
    otherwise: (schema) => schema
  }),
  seal_matchPW: yup.boolean().notRequired(),

  inspection_completed: yup.boolean().notRequired()
})

export type GateTransactionSchemaType = yup.InferType<
  typeof GateTransactionSchema
>
