import clsx from 'clsx'
import { FC } from 'react'

import { Avatar, Col, ReadOnlyFormValue, Row, Text } from '@/components/atoms'
import {
  FormRow,
  FormSection,
  TruckImageWithLoader
} from '@/features/forms/components'
import { LaneDirection } from '@/features/gate/enums'
import { getLaneDirectionName } from '@/features/gate/utils'
import { Color } from '@/styles/palette'
import { DateFormat, FontWeight, TextTypes } from '@/types/enums/ui'
import { formatDate } from '@/utils/helpers'

import styles from './IsrFormAside.module.scss'
import { FORM_SECTION_ANCHORS } from '@/features/forms/constants'

interface AsideItem {
  date: Date
  user: string
  img?: string
}

interface Props {
  checkIn: AsideItem
  checkOut: AsideItem
}

const IsrFormAside: FC<Props> = (props) => {
  const { checkIn, checkOut } = props

  const hasSingleImage = Boolean(checkIn.img) !== Boolean(checkOut.img)

  const renderDetails = (section: AsideItem, type: LaneDirection) => {
    const { date, user } = section

    const isCheckIn = type === LaneDirection.Arriving

    return (
      <Col gap={16} className={styles.details}>
        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
          className={styles.subTitle}
        >
          {getLaneDirectionName(type)}
        </Text>

        <FormRow className={styles.dateAndTime}>
          <ReadOnlyFormValue
            title="Date"
            value={formatDate(date, DateFormat.DATE)}
          />
          <ReadOnlyFormValue
            title="Time"
            value={formatDate(date, DateFormat.TIME)}
          />
        </FormRow>

        <ReadOnlyFormValue
          title="User"
          value={
            <Row gap={4} items="center">
              <Avatar
                size="sm"
                name={user}
                color={isCheckIn ? Color.purple200 : Color.yellow400}
                textColor={Color.gray700}
              />

              <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
                {user}
              </Text>
            </Row>
          }
        />
      </Col>
    )
  }

  return (
    <FormSection
      title="General"
      onlyCollapsibleOnMobile
      id={FORM_SECTION_ANCHORS.GENERAL.ID}
    >
      <Col gap={16} className={styles.isrAsideWrapper}>
        <TruckImageWithLoader
          title="Check-In"
          image={checkIn.img}
          className={clsx({ [styles.fullWidth]: hasSingleImage })}
        />
        {renderDetails(checkIn, LaneDirection.Arriving)}

        <TruckImageWithLoader
          title="Check-Out"
          image={checkOut.img}
          className={clsx({ [styles.fullWidth]: hasSingleImage })}
        />
        {renderDetails(checkOut, LaneDirection.Departing)}
      </Col>
    </FormSection>
  )
}

export default IsrFormAside
