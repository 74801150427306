import { Col, Row } from '@/components/atoms'
import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  className?: string
  columnMode?: boolean
}

const FullSpaceTemplate: FC<Props> = (props) => {
  const { className, children, columnMode = false } = props

  const WrapperComponent = columnMode ? Col : Row

  return (
    <WrapperComponent
      items="stretch"
      className={clsx(
        'tw-w-full tw-h-full tw-flex-1 tw-overflow-hidden',
        className
      )}
    >
      {children}
    </WrapperComponent>
  )
}

export default FullSpaceTemplate
