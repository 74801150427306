import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/material'
import clsx from 'clsx'
import { FC, KeyboardEvent, PropsWithChildren, useRef, useState } from 'react'

import { DropdownMenu, Row, Text } from '@/components/atoms'
import { useMobileNavigationContext } from '@/components/contexts'
import { useLastUrlPart } from '@/hooks'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { ITab } from '@/types/interfaces/ui'
import { onEnterPressed } from '@/utils/helpers'

import styles from './WithHeaderTemplate.module.scss'

interface IProps {
  title: string
  className?: string
  showBottomBorder?: boolean
  Action?: JSX.Element
  dropdownTabs?: ITab[]
}

const WithHeaderTemplate: FC<IProps & PropsWithChildren> = (props) => {
  const {
    children,
    title,
    className,
    showBottomBorder = true,
    Action,
    dropdownTabs = []
  } = props

  const MENU_ICON_LENGTH = 16

  const [tabPopoverOpen, setTabPopoverOpen] = useState<boolean>(false)

  const headerRowRef = useRef<HTMLDivElement | null>(null)

  const { toggleMobileNavigation } = useMobileNavigationContext()

  const lastUrlPart = useLastUrlPart()

  const hasDropdownTabs = dropdownTabs.length > 0

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    onEnterPressed(e, toggleMobileNavigation)
  }

  const getTitle = () => {
    if (hasDropdownTabs) {
      return dropdownTabs.find((tab) => tab.id === lastUrlPart)?.title
    }

    return title
  }

  const toggleDropdownTabs = () => setTabPopoverOpen(!tabPopoverOpen)

  return (
    <div className={styles.content}>
      <header
        className={clsx(styles.header, {
          [styles.withBorder]: showBottomBorder
        })}
      >
        <Row items="center" gap={8} ref={headerRowRef}>
          <div
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={styles.burgerIcon}
            onClick={() => toggleMobileNavigation()}
          >
            <MenuIcon width={MENU_ICON_LENGTH} height={MENU_ICON_LENGTH} />
          </div>

          <Row gap={4} items="center">
            <Text
              type={TextTypes.TEXT_XL}
              weight={FontWeight.SEMIBOLD}
              color={Color.gray700}
              {...(hasDropdownTabs && {
                onClick: toggleDropdownTabs
              })}
            >
              {getTitle()}
            </Text>

            {dropdownTabs.length > 1 && (
              <DropdownMenu
                linkMode
                items={dropdownTabs.map((item) => ({
                  id: item.id,
                  label: item.title,
                  selected: lastUrlPart === item.id
                }))}
              >
                {({ toggle }) => (
                  <IconButton
                    size="small"
                    onClick={toggle}
                    className={styles.iconButton}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                )}
              </DropdownMenu>
            )}
          </Row>
        </Row>

        <Row id="header-actions" items="center" gap={16}>
          {Action}
        </Row>
      </header>

      <div className={clsx(styles.innerContent, className)}>{children}</div>
    </div>
  )
}

export default WithHeaderTemplate
