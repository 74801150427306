import { FC, PropsWithChildren } from 'react'

import { Logo, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

import styles from './Footer.module.scss'

const Footer: FC<PropsWithChildren> = (props) => {
  const { children } = props

  return (
    <footer className={styles.footer}>
      <Row gap={20} items="center" justify="between">
        <Row items="center" gap={4}>
          <Logo size={14} color="gray" />
          <Text
            type={TextTypes.TEXT_XS}
            color={Color.gray700}
            className={styles.footerText}
          >
            Powered by Terminal
          </Text>
        </Row>

        {children}
      </Row>
    </footer>
  )
}

export default Footer
