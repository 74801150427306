import { FC, PropsWithChildren } from 'react'
import { Paper } from '@mui/material'
import { Text } from '@/components/atoms'

import styles from './AutocompleteDropdown.module.scss'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { Color } from '@/styles/palette'

interface Props extends PropsWithChildren {
  title?: string
  showTitle?: boolean
}

const AutocompleteDropdown: FC<Props> = (props) => {
  const { children, title, showTitle = true } = props

  return (
    <Paper elevation={4} {...props}>
      {!!title && showTitle && (
        <div className={styles.dropdownTitle}>
          <Text
            type={TextTypes.TEXT_XS}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray600}
          >
            {title}
          </Text>
        </div>
      )}

      {children}
    </Paper>
  )
}

export default AutocompleteDropdown
