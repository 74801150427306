import { Text } from '@/components/atoms'
import styles from './TableGroupHeader.module.scss'
import { FontWeight, TextTypes } from '@/types/enums/ui'

interface Props {
  displayName: string
}

const TableGroupHeader = ({ displayName }: Props) => {
  return (
    <Text
      className={styles.groupTableText}
      weight={FontWeight.BOLD}
      type={TextTypes.TEXT_XS}
    >
      {displayName}
    </Text>
  )
}

export default TableGroupHeader
