import { IHighOrderColumn } from '@/types/interfaces/table'

const gateTransactionsHighOrderColumns = (): IHighOrderColumn[] => [
  {
    id: 'general',
    title: 'General',
    colSpan: 4
  },
  {
    id: 'power-unit',
    title: 'Power Unit',
    colSpan: 11
  },
  {
    id: 'driver',
    title: 'Driver Details',
    colSpan: 3
  },
  {
    id: 'cargo-asset',
    title: 'Cargo Asset',
    colSpan: 12
  }
]

export default gateTransactionsHighOrderColumns
