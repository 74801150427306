import { GateTransactionEventMetadata } from '@/__generated__/graphql'
import { GateTransactionMetadata } from '@/features/gate/types'
import { DomainTransactionTypes, SealMatchesPW } from '@/features/gate/enums'
import {
  AppointmentTypes,
  CargoAssetTypes,
  EmissionType,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'

const convertTransactionToSnakeCase = (
  transaction: GateTransactionEventMetadata
): GateTransactionMetadata => ({
  lane_id: transaction.laneId,
  transaction_type: transaction.transactionType as DomainTransactionTypes,

  appointment_type: transaction.appointmentType as AppointmentTypes,

  power_unit_type: transaction.powerUnitType as PowerUnitTypes,
  power_unit_owner_id: transaction.powerUnitOwnerId,
  power_unit_license_plate_number: transaction.powerUnitLicensePlateNumber,
  power_unit_license_plate_state: transaction.powerUnitLicensePlateState,
  power_unit_carrier_usdot: transaction.powerUnitCarrierUsdot,
  power_unit_carrier_MCNum: transaction.powerUnitCarrierMCNum,
  power_unit_vin: transaction.powerUnitVin,
  power_unit_weight_class: transaction.powerUnitWeightClass as WeightClasses,
  power_unit_fuel_type: transaction.powerUnitFuelType as FuelTypes,
  power_unit_emission_type: transaction.powerUnitEmissionType as EmissionType,
  power_unit_carrier_name: transaction.powerUnitCarrierName,
  account_name: transaction.accountName,

  driver_first_name: transaction.driverFirstName,
  driver_last_name: transaction.driverLastName,
  driver_company_id: transaction.driverCompanyId,
  driver_license_number: transaction.driverLicenseNumber,
  driver_phone_number: transaction.driverPhoneNumber,
  driver_license_state: transaction.driverLicenseState,

  cargo_asset_owner_id: transaction.cargoAssetOwnerId,
  cargo_asset_license_plate_number: transaction.cargoAssetLicensePlateNumber,
  cargo_asset_license_plate_state: transaction.cargoAssetLicensePlateState,
  cargo_asset_carrier_name: transaction.cargoAssetCarrierName,
  cargo_asset_asset_type: transaction.cargoAssetAssetType as CargoAssetTypes,

  inspection_completed: transaction.inspectionCompleted as boolean,

  chassis_id: transaction.chassisId,
  load_status: transaction.loadStatus as LoadTypes,
  shipment_number: transaction.shipmentNumber,
  seal_numbers: transaction.sealNumbers,
  seal_matchPW: transaction.sealMatchPW as SealMatchesPW,

  mismatch: transaction.mismatch
})

export default convertTransactionToSnakeCase
