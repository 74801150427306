import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Col } from '@/components/atoms'
import {
  CargoAssetFormSection,
  FormCol,
  FormRow
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { FormSectionType } from '@/features/forms/enums'
import { ToggleDetails } from '@/features/gate'
import { GateTransactionSchemaType } from '@/features/gate/utils'
import { GateTransaction } from '@/__generated__/graphql'
import { CargoAssetTypes } from '@/types/enums/transactionDetails'

interface IProps {
  siteId: string
  onAdd: (type: FormSectionType) => void
  onRemove: () => void
  isPresent: boolean
}

const CheckOutCargoAsset: FC<IProps> = (props) => {
  const { siteId, onAdd, onRemove, isPresent } = props

  const { reset, trigger } = useFormContext<GateTransactionSchemaType>()

  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(
    undefined
  )

  const populateCargoAssetFields = (event: GateTransaction | undefined) => {
    setSelectedEventId(event?.id)

    const { metadata } = event || {}
    const {
      cargoAssetAssetType,
      cargoAssetOwnerId,
      cargoAssetCarrierName,
      cargoAssetLicensePlateNumber,
      cargoAssetLicensePlateState,
      chassisId
    } = metadata || {}

    reset(
      (prev) => ({
        ...prev,

        [FORM_IDS.CARGO_ASSET.TYPE]: cargoAssetAssetType as CargoAssetTypes,
        [FORM_IDS.CARGO_ASSET.OWNER_ID]: cargoAssetOwnerId || '',
        [FORM_IDS.CARGO_ASSET.CARRIER]: cargoAssetCarrierName || '',
        [FORM_IDS.CARGO_ASSET.CHASSIS_ID]: chassisId || '',
        [FORM_IDS.CARGO_ASSET.LPN]: cargoAssetLicensePlateNumber || '',
        [FORM_IDS.CARGO_ASSET.LPN_STATE]: cargoAssetLicensePlateState || ''
      }),
      { keepDirtyValues: true, keepSubmitCount: true }
    )

    trigger([FORM_IDS.CARGO_ASSET.OWNER_ID, FORM_IDS.CARGO_ASSET.LPN])
  }

  return (
    <CargoAssetFormSection
      isPresent={isPresent}
      onAdd={onAdd}
      onRemove={onRemove}
    >
      {({ isEmpty, isMinimized }) => (
        <FormCol>
          <FormRow>
            {!isMinimized && (
              <CargoAssetFormSection.OwnerId
                autoComplete
                siteId={siteId}
                selectedEventId={selectedEventId}
                onOptionSelect={populateCargoAssetFields}
              />
            )}

            <CargoAssetFormSection.LoadStatus />
          </FormRow>

          {!isEmpty && (
            <>
              <CargoAssetFormSection.ShipmentNumber />

              <Col items="stretch" gap={4}>
                <CargoAssetFormSection.SealNumbers />
                <CargoAssetFormSection.SealMatchesPW />
              </Col>
            </>
          )}

          <ToggleDetails>
            <FormCol>
              <CargoAssetFormSection.Type readOnly />

              <FormRow>
                <CargoAssetFormSection.AssetCarrier readOnly />
                <CargoAssetFormSection.ChassisId readOnly />
              </FormRow>

              <FormRow>
                <CargoAssetFormSection.LicensePlateNumber readOnly />
                <CargoAssetFormSection.LicensePlateState readOnly />
              </FormRow>
            </FormCol>
          </ToggleDetails>
        </FormCol>
      )}
    </CargoAssetFormSection>
  )
}

export default CheckOutCargoAsset
