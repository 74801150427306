import clsx from 'clsx'
import { FC } from 'react'

import TerminalLogo from '@/assets/icons/terminal-logo.svg?react'
import TerminalBigLogo from '@/assets/icons/terminal-wide-logo.svg?react'

import styles from './Logo.module.scss'

interface IProps {
  size?: number
  mini?: boolean
  className?: string
  onClick?: () => void
  color?: 'white' | 'green' | 'gray'
}

const Logo: FC<IProps> = (props) => {
  const { size = 40, mini = true, onClick, color = 'white', className } = props
  const isClickable = Boolean(onClick)

  return (
    <div
      onClick={onClick}
      className={clsx(
        className,
        styles.logoWrapper,
        { [styles.clickable]: isClickable },
        styles[color]
      )}
    >
      {mini ? (
        <TerminalLogo width={size} height={size} />
      ) : (
        <TerminalBigLogo width={size} height="auto" />
      )}
    </div>
  )
}

export default Logo
