import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { ReadOnlyProps } from '@/types/interfaces/ui'

const LicensePlateNumber: FC<ReadOnlyProps> = (props) => {
  const { readOnly, label = 'LPN' } = props

  const { control, watch } = useFormContext()

  const assetLpn = watch(FORM_IDS.CARGO_ASSET.LPN)

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={assetLpn} />
  }

  return (
    <Controller
      control={control}
      name={FORM_IDS.CARGO_ASSET.LPN}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          uppercase
          fullWidth
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default LicensePlateNumber
