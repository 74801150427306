import { Dayjs } from 'dayjs'
import { FC } from 'react'

import { Col, Input, Row } from '@/components/atoms'
import { IDateRangeFilterValue } from '@/types/interfaces/table'

interface IProps {
  value: IDateRangeFilterValue | undefined
  onChange: (value: any, close?: boolean) => void
}

const DateRangeFilter: FC<IProps> = (props) => {
  const { value, onChange } = props

  const onUpdateValue = (
    id: 'endDate' | 'startDate' | 'option',
    newValue: Dayjs | null | string
  ) => {
    const updatedDates = {
      startDate: id === 'startDate' ? newValue : value?.startDate,
      endDate: id === 'endDate' ? newValue : value?.endDate
    }

    if (!updatedDates.startDate) {
      delete updatedDates.startDate
    }

    if (!updatedDates.endDate) {
      delete updatedDates.endDate
    }

    onChange(Object.keys(updatedDates).length ? updatedDates : undefined)
  }

  return (
    <Col items="stretch" gap={6}>
      <Row items="center" justify="between" gap={8} className="tw-px-8">
        <div>
          <Input
            type="date"
            max={value?.endDate}
            value={value?.startDate}
            onChange={(value) => onUpdateValue('startDate', value)}
            placeholder="Start Date"
          />
        </div>
        -
        <div>
          <Input
            type="date"
            min={value?.startDate}
            value={value?.endDate}
            onChange={(value) => onUpdateValue('endDate', value)}
            placeholder="End Date"
          />
        </div>
      </Row>
    </Col>
  )
}

export default DateRangeFilter
