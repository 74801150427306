import { TableActions, TableColumnType } from '@/types/enums/table'
import { RowClickedEvent } from 'ag-grid-community'

const CLOSEST_ACTIONS_CELL_SELECTOR = `[col-id="${TableColumnType.Actions}"]`

const onRowClicked = (
  params: RowClickedEvent,
  clickable?: boolean,
  handleAction?: (action: TableActions, data: any) => void
) => {
  const eventTarget = params.event?.target as HTMLElement

  const selection = document.getSelection()
  if (selection?.toString()) return

  const isFromActionsCell = !!eventTarget.closest(CLOSEST_ACTIONS_CELL_SELECTOR)
  
  if (isFromActionsCell || !clickable) return

  handleAction?.(TableActions.RowClick, params.data)
}

export default onRowClicked
