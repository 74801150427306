import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined'
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined'
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined'
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined'
import { FC } from 'react'

import { Button, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IPagination } from '@/types/interfaces/ui'

interface IProps {
  onPageChange?: (page: number) => void
  pagination: IPagination
}

const TablePagination: FC<IProps> = (props) => {
  const { onPageChange, pagination } = props
  const { currentPage, totalPages, totalItems, perPage } = pagination

  const buttonClassName = 'tw-w-[24px] tw-h-[24px] !tw-p-0'

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages

  const startNumber = (currentPage - 1) * perPage + 1
  const endNumber = Math.min(currentPage * perPage, totalItems)

  const onFirstPage = () => {
    onPageChange?.(1)
  }

  const onLastPage = () => {
    onPageChange?.(totalPages)
  }

  const onNextPage = () => {
    onPageChange?.(currentPage + 1)
  }

  const onPreviousPage = () => {
    onPageChange?.(currentPage - 1)
  }

  return (
    <Row items="center" gap={16}>
      <Text type={TextTypes.TEXT_XS} color={Color.gray800}>
        {startNumber}-{endNumber} of {totalItems}
      </Text>

      <Row items="stretch" gap={4}>
        <Button
          square
          disabled={isFirstPage}
          onClick={onFirstPage}
          type="outlined"
          className={buttonClassName}
        >
          <SkipPreviousOutlinedIcon />
        </Button>

        <Button
          square
          disabled={isFirstPage}
          type="outlined"
          className={buttonClassName}
          onClick={onPreviousPage}
        >
          <NavigateBeforeOutlinedIcon />
        </Button>

        <Button
          square
          disabled={isLastPage}
          type="outlined"
          className={buttonClassName}
          onClick={onNextPage}
        >
          <NavigateNextOutlinedIcon />
        </Button>

        <Button
          square
          disabled={isLastPage}
          type="outlined"
          className={buttonClassName}
          onClick={onLastPage}
        >
          <SkipNextOutlinedIcon />
        </Button>
      </Row>
    </Row>
  )
}

export default TablePagination
