import styles from '@/components/atoms/Input/Input.module.scss'
import { InputClasses } from '@/types/interfaces/ui'

const rootClasses = { root: styles.root }

const inputClasses = {
  root: styles.input,
  disabled: styles.disabled,
  error: styles.error,
  adornedStart: styles.withIcon,
  sizeSmall: styles.small
}

const outlinedInputClasses = {
  root: styles.outlined
}

const inputLabelClasses = {
  root: styles.label,
  error: styles.error,
  asterisk: styles.asterisk
}

const helperTextClasses = {
  root: styles.helperText,
  error: styles.error
}

const iconClasses = { root: styles.iconStart }

const endIconClasses = { root: styles.iconEnd }

const classes: InputClasses = {
  rootClasses,
  inputClasses,
  endIconClasses,
  inputLabelClasses,
  helperTextClasses,
  iconClasses,
  outlinedInputClasses
}

export default classes
