import { GateTransaction } from '@/__generated__/graphql'
import { FC } from 'react'
import reactStringReplace from 'react-string-replace'
import { Col, Text } from '@/components/atoms'
import { userFriendlyState } from '@/utils/table'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

interface Props {
  searchQuery: string
  transaction: GateTransaction
}

const DriverNameAutocompleteOption: FC<Props> = (props) => {
  const { transaction, searchQuery } = props
  const { metadata } = transaction
  const {
    driverFirstName,
    driverLastName,
    driverLicenseNumber,
    driverLicenseState
  } = metadata

  const fullName = `${driverFirstName} ${driverLastName}`
  const stateFriendlyName = userFriendlyState(
    { driverLicenseState },
    'driverLicenseState'
  )

  return (
    <Col gap={4}>
      <Text resetLineHeight type={TextTypes.TEXT_MD} color={Color.gray700}>
        {reactStringReplace(fullName, searchQuery, (match) => (
          <b>{match}</b>
        ))}
      </Text>

      <Text resetLineHeight type={TextTypes.TEXT_XS} color={Color.gray600}>
        DL#: {driverLicenseNumber} ({stateFriendlyName})
      </Text>
    </Col>
  )
}

export default DriverNameAutocompleteOption
