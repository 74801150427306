import { sanityClient } from '@/services/sanity/client'
import getEnvironment from '@/utils/helpers/getNodeEnvironment'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import AppBanner from './AppBanner'
import useEnvironmentBanner from '@/hooks/useEnvironmentBanner'

interface AppBannerState {
  header: string | undefined
  headerIcon?: ReactNode | undefined
  body: string | undefined
  ActionComponent: ReactNode | undefined
}

interface AppBannerContextProps {
  state: AppBannerState
  showAppBanner: boolean
  setAppBanner: (
    header: string,
    headerIcon: ReactNode,
    body: string,
    ActionComponent?: ReactNode
  ) => void
  clearAppBanner: () => void
  setAppBannerVisible: (visible: boolean) => void
}

const AppBannerContext = createContext<AppBannerContextProps | undefined>(
  undefined
)

export const AppBannerProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<AppBannerState>({
    header: undefined,
    headerIcon: undefined,
    body: undefined,
    ActionComponent: undefined
  })

  const [showAppBanner, setShowAppBanner] = useState<boolean>(false)
  const [environmentBannerEnabled, setEnvironmentBannerEnabled] =
    useState<boolean>(false)

  const { banner, loading } = useEnvironmentBanner()

  useEffect(() => {
    if (loading || !banner) return
    setEnvironmentBannerEnabled(banner.enabled)
  }, [banner, loading])

  const setAppBanner = (
    newHeader: string,
    newHeaderIcon: ReactNode,
    newBody: string,
    newActionComponent?: ReactNode
  ) => {
    setState({
      header: newHeader,
      headerIcon: newHeaderIcon,
      body: newBody,
      ActionComponent: newActionComponent
    })
  }

  const clearAppBanner = () => {
    setState({
      header: undefined,
      headerIcon: undefined,
      body: undefined,
      ActionComponent: undefined
    })

    setShowAppBanner(false)
  }

  const setAppBannerVisible = (visible: boolean) => {
    setShowAppBanner(visible)
  }

  return (
    <AppBannerContext.Provider
      value={{
        state,
        showAppBanner,
        setAppBanner,
        clearAppBanner,
        setAppBannerVisible
      }}
    >
      {showAppBanner && state.header && environmentBannerEnabled && (
        <AppBanner
          header={state.header}
          headerIcon={state.headerIcon}
          body={state.body}
          ActionComponent={state.ActionComponent}
        />
      )}
      {children}
    </AppBannerContext.Provider>
  )
}

export const useAppBanner = () => {
  const context = useContext(AppBannerContext)
  if (!context) {
    throw new Error('useAppBanner must be used within an AppBannerContext')
  }
  return context
}
